angular.module('farmpin')
    .controller('MapController', function ($scope, $state, $timeout, MapSvc, MissionSvc) {

        MapSvc.init();

        $scope.$state = $state;
        $scope.areaInHectares = 0;
        $scope.formSubmission = false;
        $scope.mapStep = 0;
        $scope.failedToGeoLocate = '';
        $scope.formatted_location = '';
        $scope.gettingLocation = false;
        let campaign = "";

        $scope.missionSvc = MissionSvc;

        var init = function () {
            MissionSvc.getSelectedMission($state.params.mission_id).then(
                (response) => {
                    if (angular.isArray(response.data.mission.coordinates) && response.data.mission.coordinates.length > 0) {
                        $scope.findMyFields();
                        jQuery('#savePolygonArea').show();
                        MapSvc.loadExistingPolygon(response.data.mission.coordinates);
                        $scope.areaInHectares = MapSvc.getPolygonAreaInHectares();
                    }
                }
            );

            if (!!$state.params.campaign) {
                campaign = $state.params.campaign;
            }
            setTitleAndDescription();

        };

        MapSvc.subscribeOverlayChangedEvent($scope, function polygonAreaChanged() {
            $scope.areaInHectares = MapSvc.getPolygonAreaInHectares();
            $scope.$apply();
            jQuery('#savePolygonArea').show();
        });

        MapSvc.subscribeAddressSelectedEvent($scope, function addressSelected() {
            jQuery('#savePolygonArea').hide();
        });

        $scope.$on('location-found', function (event, args) {
            $scope.formatted_location = args;
            $timeout(function () {
                $scope.mapStep = 1;
            }, 0)
        });

        function setTitleAndDescription() {

            switch ($state.params.campaign) {
                case "":
                    $scope.success_title = "Where is your farm?";
                    $scope.success_description = "Find the fields you want to see maps & graphs for.";
                    break;
                // case CAMPAIGN_1:
                //     $scope.success_title = "Where is your farm?";
                //     $scope.success_description = "Find the fields where you want to pinpoint plant performance.";
                //     break;
                // case CAMPAIGN_2:
                //     $scope.success_title = "Where is your farm?";
                //     $scope.success_description = "Find the fields where you want to pinpoint plant performance.";
                //     break;
                // case CAMPAIGN_3:
                //     $scope.success_title = "Where is your farm?";
                //     $scope.success_description = "Find the fields where you want to pinpoint plant performance.";
                //     break;
                case NEW_ORDER:
                    $scope.success_title = "Where is your farm?";
                    $scope.success_description = "Find the fields you want to see maps & graphs for.";
                    break;

                default:
                    $scope.success_title = "Where is your farm?";
                    $scope.success_description = "Find the fields you want to see maps & graphs for.";

            }
        }

        $scope.updateMission = function () {
            $scope.formSubmission = true;
            MissionSvc.updateMissionRequest(
                MapSvc.getPolygonCoordinates(),
                MapSvc.getPolygonArea()
            ).then(
                (response) => MissionSvc.sendMissionDetailsToAdmin(response.data.id)
            ).then(
                (response) => $state.transitionTo('field-map-images', {mission_id: $state.params.mission_id, initLoad: true})
            );
        };

        // $scope.closeAdvisoryParent = function () {
        //     jQuery('#advisory-parent').hide();
        // };

        $scope.findMyFields = function () {
            jQuery('#thank-you-parent').toggle();
            jQuery('#actions-pain').toggle();
            jQuery('#polygon-parent').toggle();
            jQuery('#mapDiv').addClass('hidden-pseudo');
        };

        $scope.getMyLocation = function () {
            $scope.gettingLocation = true;
            MapSvc.getUserCurrentLocation().then(function () {
                $scope.gettingLocation = false;
                $scope.mapStep = 1;
            }).catch(
                function (error) {
                    $scope.gettingLocation = false;
                    return $scope.failedToGeoLocate = error;
                }
            );
        };

        $scope.doNotFindMyFields = function () {
            $state.transitionTo('home');
        };

        $scope.clearPolygonArea = function () {
            MapSvc.clearPolygonCoordinates();
        };

        $scope.deleteSearchInput = function () {
            $scope.formatted_location = ''
        };

        init();
    });
