angular.module('farmpin')

    .controller('PayController', function ($scope, $state, $timeout, MissionSvc, PayService) {
        $scope.formData = {};
        $scope.redirect_url = {};
        $scope.payment_params = {};
        $scope.confirm_payment = false;
        $scope.formSubmission = false;
        $scope.formData.mission_id = $state.params.mission_id;
        $scope.allowedCardTypes = ['Visa', 'MasterCard'];

        $scope.paymentMonths = [1,2,3,4,5,6,7,8,9,10,11,12];
        $scope.selectedMonth = $scope.paymentMonths[0];

        $scope.submitPaymentForm = function (cardType) {
            if (cardType != null) {
                $scope.formSubmission = true;
                $scope.formData.card_type = cardType;
                PayService.submitPaymentForm($scope.formData).then(res => {
                    $scope.formSubmission = false;
                    $scope.payment_params = res.data.context.params;
                    // 3d Secure requires us to post some params to a url, doing this through $http results in a cors error so have to do it this way, unless?
                    document.getElementById("paymentSubmit").action = res.data.context.redirect_url;
                    $scope.confirm_payment = true;
                })
            }
        };

        $scope.backToPayment = function () {
            $scope.confirm_payment = false;
        };

        // 3d Secure requires us to post some params to a url, doing this through $http results in a cors error so have to do it this way, unless?
        $scope.confirmPayment = function () {
            document.getElementById("paymentSubmit").submit();
        };

        $scope.init = function () {
            MissionSvc.getSelectedMission($scope.formData.mission_id).then(res => {
                $scope.formData.card_holder = res.data.mission.name;
                $scope.formData.email = res.data.mission.email;
            });

            $scope.formData.ref = '';
            $scope.formData.card_no = '';
            $scope.formData.card_holder = '';
            $scope.formData.expiry_month = '';
            $scope.formData.expiry_year = '';
            $scope.formData.cvv = '';
        };
        $scope.init();
    });
