angular.module('farmpin')
    .controller('GroundOverlayCtrl', function ($scope, $timeout, NgMap, preloader) {
        let instance = this;
        // there seems to me a multithread or other timing issue that meant an overlay reference could get
        // lost without keeping all of them in an array to make removal easier.
        instance.overlays = [];
        const mapMinZoom = 9;
        const mapMaxZoom = 20;
        instance.ngMap = {};
        instance.bounds = [];

        $scope.fetching_image = false;

        let setOverlay = function (bounds, imageUrl) {
            while (instance.overlays[0]) {
                instance.overlays.pop().setMap(null);
            }

            if (!imageUrl || !bounds) {
                return;
            }

            // add new overlay
            NgMap.getMap().then(map => {

                if (instance.ngMap && instance.ngMap.overlayMapTypes) {
                    instance.ngMap.overlayMapTypes.clear();
                }

                const overlay = new google.maps.GroundOverlay(imageUrl, bounds, {clickable: false});
                let fetching_image_timer = $timeout(function () {
                    $scope.fetching_image = true;
                }, 500);
                preloader.preloadImages([imageUrl]).then(function () {
                        $timeout.cancel(fetching_image_timer);
                        $scope.fetching_image = false;
                        overlay.setMap(map);
                        instance.overlays.push(overlay);
                    },
                    function () {
                        $scope.fetching_image = false;
                        console.log('load failed')
                    });

            });
        };

        $scope.$on('setMapOverlay', (event, bounds, imageUrl) => {
            setOverlay(bounds, imageUrl);
        });


        $scope.$on('setMapTileOverlay', (event, bounds, url) => {
            instance.bounds = bounds;
            setTileOverlay(instance.bounds, url);
        });

        let setTileOverlay = function (bounds, url) {

            while (instance.overlays[0]) {
                instance.overlays.pop().setMap(null);
            }

            if (!bounds) {
                return;
            }

            // add new overlay
            NgMap.getMap().then(map => {

                instance.ngMap = map;
                instance.ngMap.setCenter(instance.bounds.getCenter());
                instance.ngMap.fitBounds(instance.bounds);

                instance.ngMap.overlayMapTypes.clear();
                let pix4tiler = createOverlay(url);
                instance.ngMap.overlayMapTypes.insertAt(0, pix4tiler);

            });
        };

        function createOverlay(url) {
            return new google.maps.ImageMapType({

                getTileUrl: function (coord, zoom) {

                    if ((zoom < mapMinZoom) || (zoom > mapMaxZoom)) {
                        return "http://www.maptiler.org/img/none.png";
                    }

                    //https://developers.google.com/maps/documentation/javascript/coordinates

                    let mercantorProj = instance.ngMap.getProjection(); //converts the map's location into a 'world' coordinate

                    let tileSize = 256 / Math.pow(2, zoom);
                    // Assume a map at zoom level 0 is a single tile of the base tile size (256px).
                    // We then define world coordinates relative to pixel coordinates at current zoom level,
                    // using the map.getProjection() above to convert latitudes and longitudes to pixel positions on this base tile.

                    let tileBounds = new google.maps.LatLngBounds(mercantorProj.fromPointToLatLng(
                        new google.maps.Point(coord.x * tileSize, (coord.y + 1) * tileSize)
                    ), mercantorProj.fromPointToLatLng(
                        new google.maps.Point((coord.x + 1) * tileSize, coord.y * tileSize)
                    ));

                    if (tileBounds.intersects(instance.bounds) && (zoom >= mapMinZoom) && (zoom <= mapMaxZoom)) {
                        return `${url}%2F${zoom}%2F${coord.x}%2F${(Math.pow(2, zoom) - coord.y - 1)}.png?alt=media`;
                    }
                    else
                        return "http://www.maptiler.org/img/none.png";

                },
            });
        }


    });
