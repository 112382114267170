angular.module('farmpin')
.controller('HomeController', function ($scope, $state, $window, MissionSvc) {
    $scope.formData = {};
    $scope.emailFormVisible = true;
    $scope.formSubmission = false;
    let campaign = "";

    $scope.initiateMission = function(){
        $scope.formSubmission = true;
        for (let item in $scope.formData){
            if($scope.formData[item].trim().length == 0){
                return;
            }
        }

        // TODO: error handling
        MissionSvc.initiateMission($scope.formData).then(
            (response) => {
                $scope.emailFormVisible = false;
                $scope.formSubmission = false;
                let promise =  MissionSvc.sendMissionDetailsToAdmin(response.data.id);

                $scope.trackLeadEvent();

                return promise;
            }
        ).then(
            (response) => $state.transitionTo('mission-set-coordinates',
                {mission_id: response.data.id, campaign : campaign})
        );
    };

    $scope.trackLeadEvent = function(){
        if (angular.isDefined($window.fbq)){
            try {
                $window.fbq('track', 'Lead');
            }
            catch(ex){
                console.error(ex.message);
            }
        }
        else {
            console.warn('Facebook Pixel code not loaded');
        }

        if (angular.isDefined($window.ga)){
            try {
                $window.ga('send', 'event', 'Button', 'Click', 'Order');
            }
            catch(ex){
                console.error(ex.message);
            }
        }
        else{
            console.warn('Google Analytics code not loaded');
        }
    };

    $scope.init = function() {
        $scope.formData.name = '';
        $scope.formData.email = '';
        $scope.formData.cell = '';

        if ($state.current.name === CAMPAIGN_1){
            campaign = CAMPAIGN_1;
        }

        else if ($state.current.name === CAMPAIGN_2){
            campaign = CAMPAIGN_2;
        }

        else if ($state.current.name === CAMPAIGN_3){
            campaign = CAMPAIGN_3;
        }

        else if ($state.current.name === NEW_ORDER){
            campaign = NEW_ORDER;
        }

    };
    $scope.init();
});
