angular.module('farmpin')
    .controller('MissionController', function ($scope, $state, $timeout, MissionSvc) {

            $scope.missionSvc = MissionSvc;
            let firebaseMission = null;
            $scope.savingForm = false;
            $scope.mapFiles = [];
            $scope.mapTypes = {};
            $scope.acquisitionTypes = {};
            $scope.fieldForm = {};
            $scope.farmUrl = "";
            $scope.uploadAllowed = false;
            $scope.fetchingImages = false;
            $scope.fetchingFields = false;
            $scope.addingNewField = false;

            $scope.sendMissionToPilot = function () {
                MissionSvc.updateMissionRequest().then(() => MissionSvc.sendMissionToPilot());
            };

            $scope.sendFlightDataToProcessor = function () {
                MissionSvc.updateMissionRequest().then(() => MissionSvc.sendFlightDataToProcessor());
            };

            $scope.sendFlightDataToExpert = function () {
                MissionSvc.updateMissionRequest().then(() => MissionSvc.sendFlightDataToExpert());
            };

            $scope.updateMissionRequest = function () {
                $scope.savingForm = true;
                MissionSvc.updateMissionRequest().then(() => $scope.savingForm = false);
            };

            $scope.changeClientSubscription = function () {
                MissionSvc.changeClientSubscription($state.params.mission_id);
            };

            $scope.addFarmField = function () {
                $scope.addingNewField = true;
                MissionSvc.addFarmField($scope.fieldForm).then(() => {
                    getSelectedMission();
                    $scope.addingNewField = false;
                    $scope.fieldForm.field_name = '';
                    $scope.fieldForm.coordinates = '';
                });
            };

            $scope.deleteFarmField = function (farmField) {
                MissionSvc.deleteFarmField(farmField).then(() => {
                    getSelectedMission();
                });
            };


            $scope.fetchFarmFields = function () {
                $scope.fetchingFields = true;
                MissionSvc.fetchFarmFields().then(() => {
                    getSelectedMission();
                    $scope.fetchingFields = false;
                });
            };

            $scope.fetchMapImagesFromFirebase = function () {
                $scope.fetchingImages = true;
                let loadingPromises = [];

                loadingPromises.push(retrieveMapLayerTypesFromFirebase());
                loadingPromises.push(retrieveAcquisitionTypesFromFirebase());
                loadingPromises.push(retrieveExistingMapImagesFromFirebase());

                return Promise.all(loadingPromises).then(() => {
                    $scope.$apply(() => {
                        $scope.uploadAllowed = true;
                    });
                });
            };

            const stringify = function () {
                return JSON.stringify(this);
            };

            function getSelectedMission() {
                MissionSvc.getSelectedMission($state.params.mission_id).then(() => {
                    // hack to display and input coordinate json
                    // see https://stackoverflow.com/questions/17893708/angularjs-textarea-bind-to-json-object-shows-object-object
                    $scope.missionSvc.currentMission.coordinates.toString = stringify;
                    $scope.farmUrl = `/farm/${$scope.missionSvc.currentMission.id}`;
                    $scope.upload_field_files_url = '/admin/api/mission/' + $scope.missionSvc.currentMission.id + '/upload_fields';
                    $scope.$watch('missionSvc.currentMission.coordinates', function (v) {
                        // console.log(v);
                        if (typeof v === 'string') {
                            $scope.missionSvc.currentMission.coordinates = JSON.parse(v);
                            $scope.missionSvc.currentMission.coordinates.toString = stringify;
                        }
                    });
                });
            }

            $scope.init = function () {
                getSelectedMission();

                $(document).ready(() => {

                    $('.modal').modal();

                    //prepare file upload functionality
                    let mapFileUpload = document.getElementById("mapFileUpload");
                    mapFileUpload.addEventListener('change', function (e) {
                        let files = e.target.files;
                        if (!files || files.length === 0) return;

                        let uploadPromises = [];

                        for (let i = 0; i < files.length; i++) {

                            let file = files[i];

                            if (!file.name) return;
                            jQuery('#uploading-map-files').removeClass('hidden');

                            let uploadLocation = `/mission/${$scope.missionSvc.currentMission.id}/images/`;
                            let uploadPromise = uploadFileToCloudStorage(uploadLocation, file, null).then(storageInfo => {

                                let fileDate = autoDetermineDateFromFilename(file.name);
                                let imageType = autoDetermineImageTypeFromFilename(file.name);

                                let defaultAcquisitionType = getDefaultAcquisitionType();
                                jQuery('#uploading-map-files').addClass('hidden');
                                $scope.mapFiles.push(new MissionMapUpload(generateUniqueMapImageFileId(), storageInfo.name,
                                    storageInfo.downloadUrl,
                                    defaultAcquisitionType,
                                    imageType,
                                    fileDate,
                                    storageInfo.bucket,
                                    storageInfo.fullPath,
                                    storageInfo.uploadedAt));

                                $scope.$apply();
                                setDatePickerControls();

                            }, (errMessage) => {

                                jQuery('#uploading-map-files').addClass('hidden');
                                alert(errMessage);

                            }).then(() => {
                                // we don't sort the mapFiles again because we'd also need to sort the datePicker controls...
                                for (let i = 0; i < $scope.mapFiles.length; i++) {
                                    let $datePickerControl = $(`#mapFileDate${i}`).pickadate();
                                    let datePicker = $datePickerControl.pickadate('picker');
                                    datePicker.set('select', $scope.mapFiles[i].imageDate);
                                }
                            }, (errMessage) => {

                                jQuery('#uploading-map-files').addClass('hidden');
                                alert(errMessage);

                            });

                            uploadPromises.push(uploadPromise);

                        } // end file for loop

                        Promise.all(uploadPromises).then(results => {
                            $scope.saveMissionMapData();
                        });

                    });
                });

            };

            $scope.selectImageType = function (type, index) {
                $scope.mapFiles[index].imageType = type;
            };

            $scope.selectImageSource = function (imageSource, index) {
                $scope.mapFiles[index].imageSource = imageSource;
            };

            $scope.deleteMapImage = function (index) {
                $scope.mapFiles[index].fileId = "";
            };

            $scope.saveMissionMapData = function () {

                // remove the custom helper function from the copied coordinates
                let cloned_coordinates = Object.assign({}, $scope.missionSvc.currentMission.coordinates);
                delete cloned_coordinates.toString;

                let missionMapPayload = {
                    name: $scope.missionSvc.currentMission.name,
                    email: $scope.missionSvc.currentMission.email,
                    cell: $scope.missionSvc.currentMission.cell,
                    coordinates: cloned_coordinates,
                    pins: firebaseMission.pins || []
                };

                firebase.database().ref(`mission_map_images/${$scope.missionSvc.currentMission.id}`).set(missionMapPayload).then(() => {

                    let promises = [];
                    for (let i = 0; i < $scope.mapFiles.length; i++) {

                        let file = $scope.mapFiles[i];

                        if (file.fileId.length > 0) { //exclude deleted map image files

                            let imageData = {
                                name: file.fileName,
                                url: file.fileUrl,
                                acquisition_type: file.imageSource,
                                type: file.imageType,
                                date: setMapImageDate(new Date(jQuery(`#mapFileDate${i}`).val())),
                                bucket: file.bucket,
                                path: file.fullPath,
                                uploadedAt: file.uploadedAtMillis(),
                                uploadedAtIso: file.uploadedAtIso()
                            };

                            promises.push(firebase.database()
                                .ref(`mission_map_images/${$scope.missionSvc.currentMission.id}/images`)
                                .child(file.uniqueRefKey())
                                .update(imageData));
                        }


                    }

                    Promise.all(promises).then(snapshots => {

                        $('#modalSuccess').modal('open');
                        retrieveExistingMapImagesFromFirebase();

                    });

                });
            };

            function retrieveExistingMapImagesFromFirebase() {

                return firebase.database().ref(`mission_map_images/${$scope.missionSvc.currentMission.id}`).once('value').then(snapshot => {

                    if (!snapshot || !snapshot.val()) return;
                    firebaseMission = snapshot.val();

                    $scope.mapFiles = [];
                    for (let item in firebaseMission.images) {
                        let image = firebaseMission.images[item];
                        $scope.mapFiles.push(new MissionMapUpload(
                            item,
                            image.name,
                            image.url,
                            image.acquisition_type,
                            image.type,
                            new Date(image.date),
                            image.bucket,
                            image.path,
                            image.uploadedAtIso ? new Date(image.uploadedAtIso) : null
                        ));
                        $scope.mapFiles.sort((a, b) => {
                            return a.imageDate - b.imageDate;
                        });
                        $scope.$apply();
                        setDatePickerControls();
                    }

                    for (let i = 0; i < $scope.mapFiles.length; i++) {
                        let $datePickerControl = $(`#mapFileDate${i}`).pickadate();
                        let datePicker = $datePickerControl.pickadate('picker');
                        datePicker.set('select', $scope.mapFiles[i].imageDate);
                    }

                });
            }

            function retrieveAcquisitionTypesFromFirebase() {
                return firebase.database().ref('acquisition_types').once('value').then((snapshot) => {
                    $scope.acquisitionTypes = snapshot.val();
                    console.log($scope.acquisitionTypes);
                });
            }

            function getDefaultAcquisitionType() {
                let keys = Object.keys($scope.acquisitionTypes);
                for (let id = 0; id < keys.length; id++) {
                    let key = keys[id];
                    let acType = $scope.acquisitionTypes[key];
                    if (acType.hasOwnProperty('default') && acType.default) {
                        return key;
                    }
                }

                throw new Error('No default acquisition type has been defined. Refer to Firebase definitions');
            }

            function retrieveMapLayerTypesFromFirebase() {
                return firebase.database().ref('map_layer_types').orderByChild('priority').once('value').then((snapshot) => {
                    let mapTypes = {};
                    snapshot.forEach(function (child) {
                        console.log(child.val());
                        mapTypes[child.key] = child.val();
                    });

                    $scope.mapTypes = mapTypes;
                });
            }

            function setMapImageDate(date) {
                return moment(date).format('YYYY-MM-DD');
            }

            function setDatePickerControls() {

                setTimeout(function () {

                    $('.datepicker').pickadate({
                        selectMonths: true, // Creates a dropdown to control month
                        selectYears: 15 // Creates a dropdown of 15 years to control year
                    });

                }, 500);

            }

            function autoDetermineDateFromFilename(fileName) {
                let dateFormat1Re = new RegExp('(\\d{2} [a-zA-Z]{3} \\d{4})', 'i');
                let dateFormat2Re = new RegExp('(\\d{4}-\\d{2}-\\d{2})', 'i');

                let match1 = fileName.match(dateFormat1Re);
                if (match1) {
                    try {
                        return moment(match1[1], 'DD MMM YYYY').toDate();
                    }
                    catch (err) {
                        console.error(err);
                        return new Date()
                    }

                }

                let match2 = fileName.match(dateFormat2Re);
                if (match2) {
                    try {
                        return moment(match2[1], 'YYYY-MM-DD').toDate();
                    }
                    catch (err) {
                        console.error(err);
                        return new Date()
                    }
                }

                //default value
                return new Date()
            }

            function autoDetermineImageTypeFromFilename(fileName) {

                //based on file name configurations from Sentinel, extract first portion of string before hyphen - this represents the map-type code

                let hyphenIndex = fileName.indexOf("-");
                if (hyphenIndex === -1) {
                    return "MISC";
                }
                let mapType = fileName.substr(0, hyphenIndex).trim().toUpperCase();
                if (mapType.length === 0) {
                    return "MISC";
                }

                return mapType;
            }

            function uploadFileToCloudStorage(location, file, listener) {


                /**RECEIVES A FILE FROM FILE INPUT AND SAVES TO FIREBASE STORAGE. RETURNS PROMISE CONTAINING FILE IMAGE URL**/
                console.log(file);

                return new Promise((resolve, reject) => {
                    const uploadRef = firebase.storage().ref().child(location + file.name);
                    const uploadTask = uploadRef.put(file);

                    // Listen for state changes, errors, and completion of the upload.
                    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                        function (snapshot) {
                            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                            if (!!listener) listener(progress);
                            switch (snapshot.state) {
                                case firebase.storage.TaskState.PAUSED: // or 'paused'
                                    console.log('Upload is paused');
                                    break;
                                case firebase.storage.TaskState.RUNNING: // or 'running'
                                    console.log('Upload is running');
                                    break;
                            }
                        }, function (error) {
                            switch (error.code) {
                                case 'storage/unauthorized':
                                    console.log('User doesn\'t have permission to access the object');
                                    reject('User doesn\'t have permission to access the object');
                                    break;

                                case 'storage/canceled':
                                    console.log('User canceled the upload');
                                    reject('User canceled the upload');
                                    break;
                                case
                                'storage/unknown':
                                    console.log('Unknown error occurred, inspect error.serverResponse');
                                    reject('Unknown error occurred, inspect error.serverResponse');
                                    break;
                            }
                        }, function () {
                            // Upload completed successfully, now we can get the download URL
                            var downloadURL = uploadTask.snapshot.downloadURL;
                            resolve({
                                gsPath: uploadRef.toString(),
                                bucket: uploadRef.bucket,
                                fullPath: uploadRef.fullPath,
                                name: uploadRef.name,
                                fileName: file.name,
                                downloadUrl: downloadURL,
                                uploadedAt: new Date()
                            });

                        });

                });

            }


            $scope.init();

            function generateUniqueMapImageFileId() {
                var text = "";
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

                for (var i = 0; i < 5; i++)
                    text += possible.charAt(Math.floor(Math.random() * possible.length));

                return text;
            }

        }
    )
;
