angular.module('farmpin')

    .factory('PayService', function ($http) {

        var service = {

            submitPaymentForm: function (formData) {
                var req = {
                    method: 'POST',
                    url: '/api/peach/',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    timeout: 8000,
                    data: {
                        'payment': formData
                    }
                };

                return $http(req);
            },

            fetchResponse: function (token_id) {
                return $http.get('/api/peach/' + token_id)
            }

        };

        return service;

    });
