angular.module('farmpin')
    .controller('NextStepsController', function ($scope, $state, MissionSvc) {

        $scope.$state = $state;
        $scope.campaignType = "";
        $scope.campaign1 = CAMPAIGN_1;
        $scope.campaign2 = CAMPAIGN_2;
        $scope.campaign3 = CAMPAIGN_3;
        $scope.new_order = NEW_ORDER;
        //$scope.payment_received = PAYMENT_RECEIVED;
        $scope.mission_id = $state.params.mission_id;

        var init = function () {
            if (!MissionSvc.currentMission) {
                MissionSvc.getSelectedMission($state.params.mission_id);
            }
            if (!!$state.params.campaign) {
                $scope.campaignType = $state.params.campaign;
            }
        };

        $(document).ready(function () {
            $('.collapsible').collapsible();
        });

        init();
    });
