'use strict';
const CAMPAIGN_1 = "mielie-health-map";
const CAMPAIGN_2 = "koring";
const CAMPAIGN_3 = "citrus";
const CAMPAIGN_4 = "mielies";
const CAMPAIGN_5 = "mielies2";
const NEW_ORDER = "new-order";
const MOISTURE_MAP_TYPE = "NDMI";
const PLANT_HEALTH_TYPE = "SAVI";

const ACQ_TYPE_SATELLITE = "satellite";
const ACQ_TYPE_DRONE = "drone";


angular.module('farmpin', [
    'ui.router', 'credit-cards', 'firebase', 'ngMap'
])
    .config(['$locationProvider', function ($locationProvider) {
        $locationProvider.html5Mode(true);
    }])
    .config(function ($stateProvider, $urlRouterProvider) {

        $stateProvider

            .state('home', {
                url: '/',
                templateUrl: '/static/partials/home.html',
                controller: 'HomeController'
            })

            .state('home-german', {
                url: '/translations/german',
                templateUrl: '/static/partials/translations/german/home.html',
                controller: 'HomeController'
            })

            .state('irricheck', {
                url: '/land/irricheck',
                templateUrl: '/static/partials/irricheck.html',
                controller: 'HomeController'
            })

            .state(`${CAMPAIGN_1}`, {
                url: `/land/${CAMPAIGN_1}`,
                templateUrl: `/static/partials/${CAMPAIGN_1}.html`,
                controller: 'HomeController'
            })

            .state(`${CAMPAIGN_2}`, {
                url: `/land/${CAMPAIGN_2}`,
                templateUrl: `/static/partials/${CAMPAIGN_2}.html`,
                controller: 'HomeController'
            })

            .state(`${CAMPAIGN_3}`, {
                url: `/land/${CAMPAIGN_3}`,
                templateUrl: `/static/partials/${CAMPAIGN_3}.html`,
                controller: 'HomeController'
            })

            .state(`${CAMPAIGN_4}`, {
                url: `/land/${CAMPAIGN_4}`,
                templateUrl: `/static/partials/${CAMPAIGN_4}.html`,
                controller: 'HomeController'
            })

            .state(`${CAMPAIGN_5}`, {
                url: `/land/${CAMPAIGN_5}`,
                templateUrl: '/static/partials/mielies_b.html',
                controller: 'HomeController'
            })

            .state('campaign-get-started', {
                url: '/get-started',
                templateUrl: `/static/partials/campaign-form.html`,
                controller: 'HomeController'
            })

            .state(`${NEW_ORDER}`, {
                url: '/order',
                templateUrl: '/static/partials/order.html',
                controller: 'HomeController'
            })

            .state('mission', {
                url: '/mission/:mission_id/progress',
                templateUrl: '/static/partials/mission.html',
                controller: 'MissionController'
            })

            .state('expert', {
                url: '/mission/:mission_id/progress/expert',
                templateUrl: '/static/partials/expert.html',
                controller: 'MissionController'
            })

            .state('pilot', {
                url: '/mission/:mission_id/progress/pilot',
                templateUrl: '/static/partials/pilot.html',
                controller: 'MissionController'
            })

            .state('processor', {
                url: '/mission/:mission_id/progress/processor',
                templateUrl: '/static/partials/processor.html',
                controller: 'MissionController'
            })

            .state('mission-set-coordinates', {
                url: '/mission/:mission_id/set-coordinates/:campaign',
                templateUrl: '/static/partials/map.html',
                controller: 'MapController'
            })

            .state('mission-set-coordinates-done', {
                url: '/mission/:mission_id/set-coordinates-done/:campaign',
                templateUrl: '/static/partials/next-steps.html',
                controller: 'NextStepsController'
            })

            .state('product-comparison', {
                url: '/product-comparison',
                templateUrl: '/static/partials/product-comparison.html',
                controller: 'HomeController'
            })

            .state('crop-insight', {
                url: '/crop-insight',
                templateUrl: '/static/partials/crop-insight.html',
                controller: 'HomeController'
            })

            .state('purchase', {
                url: '/mission/:mission_id/purchase',
                templateUrl: '/static/partials/pay.html',
                controller: 'PayController'
            })

            .state('pay-response', {
                url: '/mission/:mission_id/payment-response?id&resourcePath',
                templateUrl: '/static/partials/pay_response.html',
                controller: 'PayResponseController'
            })

            .state('example-report', {
                url: '/example-report',
                templateUrl: '/static/partials/example-report.html',
                controller: 'HomeController'
            })

            /*******FIELD MAP IMAGES ROUTES*********/

            .state('field-map-images', {
                url: '/farm/:mission_id',
                templateUrl: '/static/partials/field_map_images.html',
                controller: 'FieldMapImagesController',
                params: {
                    initLoad: false
                }
            });


        // catch all route
        // send users to the form page
        $urlRouterProvider.otherwise('/');
    });
