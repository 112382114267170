angular.module('farmpin')

    .factory('MapSvc', function ($http, $q, $rootScope) {
        var latitude = -33.825834;
        var longitude = 18.588947;
        var map_zoom = 14;
        var polygonArea = 0;
        var polygonCoordinates;
        var Polygon;
        var mapOverlays = [];
        var drawingManager = null;

        return {
            map: null,
            searchLocationMarker: new google.maps.Marker(),
            mapOptions: {
                center: new google.maps.LatLng(latitude, longitude),
                zoom: map_zoom,
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                mapTypeId: google.maps.MapTypeId.HYBRID
            },

            addMarker: function (marker, position, map) {
                marker.setPosition(position);
                marker.setMap(map);
            },

            subscribeOverlayChangedEvent: function (scope, callback) {
                var handler = $rootScope.$on('polygon-overlay-changed', callback);
                scope.$on('$destroy', handler);
            },

            subscribeAddressSelectedEvent: function (scope, callback) {
                var handler = $rootScope.$on('autocomplete-address-selected', callback);
                scope.$on('$destroy', handler);
            },

            init: function () {
                var instance = this;

                this.map = new google.maps.Map(document.getElementById("mapDiv"), this.mapOptions);

                drawingManager = new google.maps.drawing.DrawingManager({
                    drawingMode: google.maps.drawing.OverlayType.POLYGON,
                    drawingControl: false,
                    drawingControlOptions: {
                        position: google.maps.ControlPosition.BOTTOM_CENTER,
                        drawingModes: ['polygon']
                    },
                    polygonOptions: {
                        fillColor: '#F57713',
                        strokeColor: '#F57713',
                        clickable: true,
                        editable: true
                    }
                });

                drawingManager.setMap(this.map);

                google.maps.event.addListener(drawingManager, "overlaycomplete", function (event) {
                    mapOverlays.push(event);
                    if (event.type != google.maps.drawing.OverlayType.MARKER) {
                        // Switch back to non-drawing mode after drawing a shape.
                        drawingManager.setDrawingMode(null);

                        var newShape = event.overlay;
                        polygonCoordinates = event.overlay.getPath().getArray();
                        newShape.type = event.type;
                        polygonArea = google.maps.geometry.spherical.computeArea(event.overlay.getPath());

                        google.maps.event.addListener(event.overlay.getPath(), 'insert_at', function () {
                            polygonArea = google.maps.geometry.spherical.computeArea(event.overlay.getPath());
                            $rootScope.$emit('polygon-overlay-changed');
                        });

                        google.maps.event.addListener(event.overlay.getPath(), 'set_at', function () {
                            polygonArea = google.maps.geometry.spherical.computeArea(event.overlay.getPath());
                            $rootScope.$emit('polygon-overlay-changed');
                        });

                        google.maps.event.addListener(newShape, 'rightclick', function (mev) {
                            if (mev.vertex != null) {
                                newShape.getPath().removeAt(mev.vertex);
                            }
                        });

                        $rootScope.$emit('polygon-overlay-changed');

                    }
                });

                // add autocomplete to the address search textbox
                var input = document.getElementsByClassName('mapTypeField');

                var autocomplete = [];

                for (let i = 0; i < input.length; i++) {
                    autocomplete.push(new google.maps.places.Autocomplete(input[i]));
                }

                for (let j = 0; j < autocomplete.length; j++) {
                    autocomplete[j].bindTo('bounds', this.map);
                    google.maps.event.addListener(autocomplete[j], 'place_changed', function () {
                        var place = autocomplete[j].getPlace();
                        if (typeof place == "undefined" || place == null || typeof place.geometry == "undefined" || typeof place.geometry.location.lat() == "undefined") return;

                        var location = new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());

                        instance.map.setCenter(location);
                        instance.map.setZoom(16);
                        instance.clearPolygonCoordinates();

                        $rootScope.$broadcast('location-found', place.formatted_address);

                    });
                }

            },

            getPolygonArea: function () {
                return polygonArea;
            },

            getPolygonAreaInHectares: function () {
                return polygonArea * 0.0001;
            },

            getPolygonCoordinates: function () {
                return polygonCoordinates;
            },

            loadExistingPolygon: function (coordinates) {
                // Construct the polygon.
                drawingManager.setDrawingMode(null);

                Polygon = new google.maps.Polygon({
                    paths: coordinates,
                    fillColor: '#F57713',
                    strokeColor: '#F57713',
                    clickable: true,
                    editable: true
                });
                Polygon.setMap(this.map);
                this.map.setCenter(coordinates[0]);

                polygonArea = google.maps.geometry.spherical.computeArea(Polygon.getPath());

                google.maps.event.addListener(Polygon.getPath(), 'insert_at', function () {
                    polygonCoordinates = Polygon.getPath().getArray();
                    polygonArea = google.maps.geometry.spherical.computeArea(Polygon.getPath());
                });

                google.maps.event.addListener(Polygon.getPath(), 'set_at', function () {
                    polygonCoordinates = Polygon.getPath().getArray();
                    polygonArea = google.maps.geometry.spherical.computeArea(Polygon.getPath());
                    $rootScope.$emit('polygon-overlay-changed');
                });

                google.maps.event.addListener(Polygon, 'rightclick', function (mev) {
                    if (mev.vertex != null) {
                        Polygon.getPath().removeAt(mev.vertex);
                    }
                });
            },

            clearPolygonCoordinates: function () {

                for (let i = 0; i < mapOverlays.length; i++) {
                    mapOverlays[i].overlay.setMap(null);
                }

                if (Polygon) {
                    Polygon.setMap(null);
                }

                mapOverlays = [];
                polygonArea = 0;
                polygonCoordinates = null;
                drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
                $rootScope.$emit('autocomplete-address-selected');
            },


            getUserCurrentLocation: function () {
                var deferred = $q.defer();

                if (!navigator.geolocation) {
                    deferred.reject("Your browser does not support location detection.");
                    return;
                }

                var instance = this;

                var locationSuccessHandler = function (position) {
                    if (!instance.map) {
                        return;
                    }
                    var latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                    instance.map.setCenter(latLng);
                    instance.map.setZoom(16);

                    deferred.resolve(position);
                };

                var locationErrorHandler = function (error) {
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            console.log("Geolocation: User denied the request.");
                            deferred.reject("Your browser seems to be denying us your location.\nCheck your location settings or try searching for the nearest town to your farm below.");
                            break;
                        case error.POSITION_UNAVAILABLE:
                            console.log("Geolocation: Location information is unavailable.");
                            deferred.reject("Oh shucks, we couldn’t find your location.\nTry searching for the nearest town to your farm below.");
                            break;
                        case error.TIMEOUT:
                            console.log("Geolocation: The request to get user location timed out.");
                            deferred.reject("Oh shucks, we couldn’t find your location.\nTry searching for the nearest town to your farm below.");
                            break;
                        case error.UNKNOWN_ERROR:
                            console.log("Geolocation: An unknown error occurred.");
                            deferred.reject("Oh shucks, we couldn’t find your location.\nTry searching for the nearest town to your farm below.");
                            break;
                    }
                };

                var options = {
                    enableHighAccuracy: true,
                    timeout: 10000,
                    maximumAge: 0
                };

                navigator.geolocation.getCurrentPosition(locationSuccessHandler, locationErrorHandler, options);

                return deferred.promise;
            }
        };

    });
