angular.module('farmpin')

    .factory('MissionSvc', function ($http) {

        return {

            currentMission: null,

            initiateMission: function (formData) {
                var req = {
                    method: 'POST',
                    url: '/api/mission/',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    timeout: 8000,
                    data: {
                        'name': formData.name,
                        'email': formData.email,
                        'cell': formData.cell,
                        'language': formData.language,
                        'crops': formData.crops,
                    }
                };

                return $http(req).then((response) => {
                    this.currentMission = response.data;
                    return response;
                });
            },

            updateMissionRequest: function (coordinates, area) {
                if (angular.isDefined(coordinates)) {
                    this.currentMission.coordinates = coordinates;
                    this.currentMission.coordinates.toString = function(){
                        return JSON.stringify(this);
                    };
                }

                if (angular.isDefined(area)) {
                    this.currentMission.area = area;
                }

                let req = {
                    method: 'PUT',
                    url: '/api/mission/' + this.currentMission.id,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    timeout: 8000,
                    data: {
                        'mission': this.currentMission
                    }
                };

                return $http(req);
            },

            sendMissionDetailsToAdmin: function (missionId) {
                return $http.get('/api/mission/' + missionId + '/email');
            },

            getSelectedMission: function (missionId) {
                return $http.get('/api/mission/' + missionId).then((response) => {
                    this.currentMission = response.data.mission;
                    return response;
                });
            },

            sendMissionToPilot: function () {
                return $http.get('/api/mission/' + this.currentMission.id + '/email/pilot');
            },

            sendFlightDataToProcessor: function () {
                return $http.get('/api/mission/' + this.currentMission.id + '/email/processor');
            },

            sendFlightDataToExpert: function () {
                return $http.get('/api/mission/' + this.currentMission.id + '/email/expert');
            },

            sendLiveShareLinkToAdmin: function(liveShareLink) {
                return $http.get('/api/mission/' + this.currentMission.id + '/email/live_share_link?link=' + encodeURIComponent(liveShareLink));
            },
            changeClientSubscription: function (mission_id) {
                return $http.get('/admin/api/mission/' + mission_id + '/change_subscription');
            },

            addFarmField: function (fieldDetails) {
                let req = {
                    method: 'POST',
                    url: '/admin/api/field/create/' + this.currentMission.id,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    timeout: 8000,
                    data: {
                        'field_name': fieldDetails.field_name,
                        'coordinates': fieldDetails.coordinates,
                    }
                };

                return $http(req);
            },

            deleteFarmField: function (farmField) {
                return $http.get('/admin/api/field/delete/' + this.currentMission.id + '/' + farmField.id);
            },

            fetchFarmFields: function () {
                return $http.get('/api/mission/' + this.currentMission.id + '/fetch_farm_fields');
            }
        };

    });
