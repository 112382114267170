angular.module('farmpin')

    .controller('PayResponseController', function ($scope, $state, PayService) {

        $scope.test = $state.params.id;

        $scope.init = function(){
            PayService.fetchResponse($state.params.id).then(res => {
                $scope.pay_response = res.data.response.result;
            })
        };

        $scope.init();
    });
