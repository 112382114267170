'use strict';

angular.module('farmpin.mygate', [])

    .factory('MyGateService', ['$http', function($http){
        var service = {
            check3dSecure : function(cardInfo, requestId){
                var instance = this;

                var values = {
                    'card_no': cardInfo.card_no,
                    'card_type': cardInfo.ccType,
                    'card_holder': cardInfo.card_holder,
                    'expiry_month': cardInfo.expiry_month,
                    'expiry_year': cardInfo.expiry_year,
                    'cvv': cardInfo.cvv,

                    'sell_id': requestId
                };

                var req = {
                    method: 'POST',
                    url: '/api/mygate/3dsecurelookup',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    timeout: 80000,
                    data: {values: values}
                };

                return $http(req).then(function successHandler(response) {
                    console.log(response);
                    if (response.data.require3d) {
                        instance._post_to_3d_secure(response.data);
                    } else {
                        return instance.process_and_settle(response.data, cardInfo, requestId); // pass in transaction index
                    }

                }, function errorHandler(response) {
                    console.log(response);
                });
            },

            _add_form_fields : function (form, data) {
                if (data != null) {
                    $.each(data, function (name, value) {
                        if (value != null) {
                            var input = $("<input></input>").attr("type", "hidden").attr("name", name).val(value);
                            form.append(input);
                        }
                    });
                }
            },

            _post_to_3d_secure : function(lookup_response){
                var fields = {
                    PaReq: lookup_response.pareqmsg,
                    MD: lookup_response.transactionindex,
                    TermUrl: lookup_response.termurl
                };

                // build form
                var form = $('<form></form>');
                form.attr("action", lookup_response.acsurl);
                form.attr("method", "POST");
                form.attr("style", "display:none;");
                this._add_form_fields(form, fields);
                $("body").append(form);

                // submit form
                form.submit();
                form.remove();
            },

            process_and_settle : function (secure3d_data, cardInfo, requestId) {

                var values = {
                    'card_no': cardInfo.card_no,
                    'card_type': cardInfo.ccType,
                    'card_holder': cardInfo.card_holder,
                    'expiry_month': cardInfo.expiry_month,
                    'expiry_year': cardInfo.expiry_year,
                    'cvv': cardInfo.cvv,
                    'transaction_index': secure3d_data.transactionindex,

                    'sell_id': requestId
                };

                var req = {
                    method: 'POST',
                    url: '/api/mygate/processandsettle',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    timeout: 80000,
                    data: {values: values}
                };

                return $http(req).then(function successHandler(response) {
                    // if (response.data == 0 || response.data == 1) {
                    //     $scope.process_payment = false;
                    //     $scope.payment = true;
                    //     $state.transitionTo('sell-safe/form.pick-up',{request_id: $scope.request_id});
                    // } else {
                    //     $scope.process_payment = false;
                    //     $scope.mygate_failed = response.data;
                    //     $('#modal1').openModal();
                    // }

                }, function errorHandler(response) {
                    console.log(response);
                });

            }
        };

        return service;
    }]);
