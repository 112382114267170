angular.module('farmpin')
    .controller('FieldMapImagesController', function ($scope, $state, $window, $firebaseArray, MissionSvc, NgMap, MissionMapImageList) {

        $scope.missionId = $state.params.mission_id;
        $scope.initLoad = $state.params.initLoad;
        $scope.missionSvc = MissionSvc;
        $scope.mapTypes = {};
        $scope.acquisitionTypes = {};
        $scope.liveShareStarting = false;
        $scope.liveShareActive = false;
        $scope.showDownloadingImageryMessage = true;
        $scope.addPinModeActive = false;
        $scope.selectedPin = null;

        $scope.missionMapImagesArray = [];
        $scope.missionDates = [];
        $scope.selectedMissionDate = null;
        $scope.selectedMissionImage = null;
        $scope.availableImagesForMissionDate = [];

        $scope.pinCropIssues = [
            {
                name: 'Soil',
                url: '/static/img/markers/Crop_Issue_Soil.svg',
                scaledSize: [40, 55],
                labelOrigin: [20, 35]
            },
            {
                name: 'Pests',
                url: '/static/img/markers/Crop_Issue_Pests.svg',
                scaledSize: [40, 55],
                labelOrigin: [20, 35]
            },
            {
                name: 'Diseases',
                url: '/static/img/markers/Crop_Issue_Diseases.png',
                scaledSize: [40, 55],
                labelOrigin: [20, 35]
            },
            {
                name: 'Water',
                url: '/static/img/markers/Crop_Issue_Water.png',
                scaledSize: [40, 55],
                labelOrigin: [20, 35]
            },
            {
                name: 'Fertiliser',
                url: '/static/img/markers/Crop_Issue_Fertiliser.png',
                scaledSize: [40, 55],
                labelOrigin: [20, 35]
            },
            {
                name: 'Mechanical',
                url: '/static/img/markers/Crop_Issue_Mechanical.png',
                scaledSize: [40, 55],
                labelOrigin: [20, 35]
            },
            {
                name: 'Weather',
                url: '/static/img/markers/Crop_Issue_Weather.png',
                scaledSize: [40, 55],
                labelOrigin: [20, 35]
            },
            {
                name: 'Not sure',
                url: '/static/img/markers/Crop_Issue_Not_Sure.png',
                scaledSize: [40, 55],
                labelOrigin: [20, 35],
                default: true
            }
        ];

        const lowPriorityMarkerType = {
            name: 'Low priority',
            url: '/static/img/markers/Small_Pin.png',
            scaledSize: [30, 32],
            labelOrigin: [15, 15]
        };

        $scope.iconObjectFromPin = function (pin) {
            // {url:'/static/img/markers/Crop_Issue_Not_Sure.png', scaledSize:[40,55], labelOrigin:[20,35]}
            if (!pin.highPriority) {
                return lowPriorityMarkerType;
            }

            const result = $scope.pinCropIssues.filter((obj) => {
                return obj.name === pin.markerType;
            });

            if (result.length > 0) {
                return result[0];
            }

            return defaultMarkerType();
        };

        const defaultMarkerType = function () {
            const result = $scope.pinCropIssues.filter((obj) => {
                return obj.hasOwnProperty('default') && obj.default === true;
            });

            if (result.length > 0) {
                return result[0];
            }

            return {
                name: 'Not sure',
                url: '/static/img/markers/Crop_Issue_Not_Sure.png',
                scaledSize: [40, 55],
                labelOrigin: [20, 35]
            };
        };

        const firebasePinsRef = firebase.database().ref('mission_map_images/' + $scope.missionId + '/pins');
        $scope.pins = $firebaseArray(firebasePinsRef);
        $scope.showPins = true;

        let shouldSendLiveShareLinkToAdmin = false;
        let centerChangedFromRemote = false;
        let zoomChangedFromRemote = false;

        let map = {};
        let bounds;
        const savi_index = '/static/img/savi.svg';
        const ndmi_index = '/static/img/Moisture.svg';
        const health_index = '/static/img/Health.svg';

        //polygon.getbounds() doesn't exist in Maps V3, for some ridiculous reason!
        if (!google.maps.Polygon.prototype.getBounds) {

            google.maps.Polygon.prototype.getBounds = function () {
                let newBounds = new google.maps.LatLngBounds();

                if (this.getPath) {
                    console.log('getPath chosen');
                    this.getPath().forEach(function (element, index) {
                        newBounds.extend(element)
                    });
                }
                else if (this.getPaths) {
                    console.log('getPaths chosen');
                    this.getPaths()[0].forEach(function (element, index) {
                        newBounds.extend(element);
                    });
                }
                else {
                    console.error("Neither the 'getPath' nor the 'getPaths' function is defined on google.maps.Polygon.prototype - can't determine bounds!")
                }

                return newBounds;
            }
        }

        $scope.reloadWindow = function () {
            $window.location.reload();
        };

        $scope.showColorCodeIndex = function () {
            if ($scope.selectedMissionImage.data.type === 'SAVI') {
                return savi_index;
            } else if ($scope.selectedMissionImage.data.type === 'NDMI') {
                return ndmi_index;
            } else if ($scope.selectedMissionImage.data.type === 'NDVI-LATE' || $scope.selectedMissionImage.data.type === 'NDVI-EARLY') {
                return health_index;
            }
        };

        $scope.toggleLiveShare = function () {
            if (!$scope.liveShareActive) {
                $scope.liveShareStarting = true;
                shouldSendLiveShareLinkToAdmin = true;
            }
            TogetherJS(); // starts or stops a TogetherJS session
        };

        let TogetherJS_OnReady = function () {
            console.log('TogetherJS ready');

            if (shouldSendLiveShareLinkToAdmin) {
                MissionSvc.sendLiveShareLinkToAdmin(TogetherJS.shareUrl());
                shouldSendLiveShareLinkToAdmin = false;
            }
            $scope.$apply(function () {
                $scope.liveShareStarting = false;
                $scope.liveShareActive = true;
            });

            // hook up listeners to sync panning and zooming across TogetherJS sessions
            map.addListener('center_changed', map_OnCenterChanged);
            map.addListener('zoom_changed', map_OnZoomChanged);
        };

        let TogetherJS_OnClose = function () {
            console.log('TogetherJS close');
            $scope.$apply(function () {
                $scope.liveShareActive = false;
            });
        };

        let TogetherJS_OnMsgCenterChanged = function (msg) {
            if (!msg.sameUrl) {
                // ignore messages from different URLs
                return;
            }

            centerChangedFromRemote = true;
            try {
                map.setCenter(msg.value);
            }
            finally {
                centerChangedFromRemote = false;
            }
        };

        let TogetherJS_OnMsgZoomChanged = function (msg) {
            if (!msg.sameUrl) {
                // ignore messages from different URLs
                return;
            }

            zoomChangedFromRemote = true;
            try {
                map.setZoom(msg.value);
            }
            finally {
                zoomChangedFromRemote = false;
            }
        };

        let TogetherJS_OnClientJoinedSession = function (msg) {
            if (!msg.sameUrl) {
                // ignore messages from different URLs
                return;
            }

            map_OnCenterChanged();
            map_OnZoomChanged();
        };

        let map_OnCenterChanged = function () {
            if (centerChangedFromRemote) {
                // ignore events that are raised as a result of TogetherJS_OnMsgCenterChanged
                return;
            }
            let newCenter = map.getCenter();
            TogetherJS.send({type: 'centerChanged', value: newCenter.toJSON()});
        };

        let map_OnZoomChanged = function () {
            if (zoomChangedFromRemote) {
                // ignore events that are raised as a result of TogetherJS_OnMsgZoomChanged
                return;
            }
            let newZoom = map.getZoom();
            TogetherJS.send({type: 'zoomChanged', value: newZoom});
        };

        const retrieveMapLayerTypesFromFirebase = function () {

            return new Promise((resolve, reject) => {

                firebase.database().ref('map_layer_types').orderByChild('priority').on('value', (snapshot) => {

                    let mapTypes = {};
                    snapshot.forEach(function (child) {
                        mapTypes[child.key] = child.val();
                    });

                    $scope.mapTypes = mapTypes;
                    resolve();

                });

            });

        };

        const retrieveAcquisitionTypesFromFirebase = function () {
            return firebase.database().ref('acquisition_types').once('value').then((snapshot) => {
                $scope.acquisitionTypes = snapshot.val();
            });
        };

        const retrieveCoordinatesFromFirebase = function () {
            return firebase.database().ref(`mission_map_images/${$scope.missionId}/coordinates`).once('value');
        };

        const getDefaultAcquisitionType = function () {
            let defaultAcquisitionType = null;

            Object.keys($scope.acquisitionTypes).forEach((key) => {
                const item = $scope.acquisitionTypes[key];
                if (item.hasOwnProperty('default') && item.default) {
                    defaultAcquisitionType = item;
                }
            });

            return defaultAcquisitionType;
        };

        $scope.getMissionDateIcon = function (item) {
            const acquisitionType = $scope.acquisitionTypes[item.imageSource] || getDefaultAcquisitionType();

            if (item.equals($scope.selectedMissionDate)) {
                return acquisitionType['selected_img'];
            }
            else {
                return acquisitionType['default_img'];
            }
        };

        $scope.pinDisplayFilter = function (value, index, array) {
            if (value.deleted == true) {
                return false;
            }
            if (!$scope.selectedMissionImage) {
                return false;
            }

            return (!value.hasOwnProperty('startDate') || (new Date(value.startDate)).getTime() <= $scope.selectedMissionImage.getImageDate().getTime()) &&
                (!value.hasOwnProperty('endDate') || $scope.selectedMissionDate.getImageDate().getTime() <= (new Date(value.endDate)).getTime());
        };

        $scope.pinsForDate = function (imageDate) {
            return $scope.pins.filter((value, index, array) => {
                if (value.deleted == true) {
                    return false;
                }

                return (!value.hasOwnProperty('startDate') || (new Date(value.startDate)).getTime() <= imageDate.getTime()) &&
                    (!value.hasOwnProperty('endDate') || imageDate.getTime() <= (new Date(value.endDate)).getTime());

            }).length;
        };

        const highestPinDisplayId = function (pinArray) {
            const highest = pinArray.reduce((acc, cur, index, array) => {
                return acc > cur.displayId ? acc : cur.displayId;
            }, 0);

            return highest || 0;
        };

        const pinDropEventHandler = function (event) {
            const defaultMarkerTypeForPin = defaultMarkerType();

            const pin = {
                coordinates: {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng()
                },
                startDate: moment($scope.selectedMissionImage.getImageDate()).format("YYYY-MM-DD"),
                markerType: defaultMarkerTypeForPin.name,
                missionId: $scope.missionId,
                resolved: false,
                addedToLayer: $scope.selectedMissionImage.data.type,
                description: '',
                createdAt: (new Date()).toISOString(),
                // https://developers.google.com/maps/documentation/urls/guide
                mapsUrl: 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(event.latLng.lat() + ',' + event.latLng.lng()),
                deleted: false,
                highPriority: true,
                displayId: highestPinDisplayId($scope.pins) + 1
            };

            $scope.pins.$add(pin).then((ref) => {
                for (const key of Object.keys(map.markers)) {
                    if (map.markers[key].data.$id === ref.key) {
                        $scope.showPinDetails(null, $scope.pins.$getRecord(ref.key), map.markers[key]); // have to use $getRecord, don't try to pass pin directly
                        break;
                    }
                }
            });

            $scope.toggleAddPinMode();
        };

        let mapClickListener;

        $scope.toggleAddPinMode = function () {
            // we need date information for the pin
            if (!$scope.addPinModeActive && $scope.selectedMissionDate) {
                $scope.addPinModeActive = true;
                map.setOptions({
                    draggableCursor: 'url(static/img/markers/Add_Pin.svg) 20 58, crosshair'
                });
                mapClickListener = google.maps.event.addListenerOnce(map, 'click', pinDropEventHandler);
            }
            else {
                $scope.addPinModeActive = false;
                // revert to google maps default which is NOT the same as 'grab'
                map.setOptions({
                    draggableCursor: '',
                });
                // this is needed because even though addListenerOnce is used above, the event will not have fired if you click cancel
                mapClickListener.remove();
            }
        };

        $scope.togglePinsVisible = function () {
            map.hideInfoWindow('pinDetails');
        };

        $scope.imageTypeButtonClass = function () {

            //calculates the class for the button parent, based on the number of available images for the selected date
            //relevant for media queries - if there are more image types, the button parent needs to be hidden at a greater screen width
            const i = $scope.availableImagesForMissionDate.length;

            if (i <= 3) {
                return "availableImageButtonParent3";
            }
            if (i <= 6) {
                return "availableImageButtonParent6";
            }
            return "availableImageButtonParent9";


        };

        $scope.imageTypeDropdownClass = function () {

            //calculates the class for the dropdown parent, based on the number of available images for the selected date
            //relevant for media queries - if there are more image types, the dropdown needs to be shown at a greater screen width
            const i = $scope.availableImagesForMissionDate.length;

            if (i <= 3) {
                return "availableImageDropdownParent3";
            }
            if (i <= 6) {
                return "availableImageDropdownParent6";
            }
            return "availableImageDropdownParent9";


        };

        $scope.onPinDragStart = function (event, pin, marker) {
            // pin must come straight out of the firebase array, otherwise the binding breaks
            // don't try to get it from the marker's 'data' property, even though it appears to be the same object (the Angular expression interpolation somehow breaks it?)
            $scope.selectedPin = pin;
            map.hideInfoWindow('pinDetails', marker || this); // this = the marker object in this context, if called by on-dragstart

            pin.coordinates.lat = event.latLng.lat();
            pin.coordinates.lng = event.latLng.lng();

            pin.mapsUrl = 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(event.latLng.lat() + ',' + event.latLng.lng());
        };

        $scope.onPinDrag = function (event, pin, marker) {
            pin.coordinates.lat = event.latLng.lat();
            pin.coordinates.lng = event.latLng.lng();

            pin.mapsUrl = 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(event.latLng.lat() + ',' + event.latLng.lng());
        };

        $scope.onPinDragEnd = function (event, pin, marker) {
            pin.coordinates.lat = event.latLng.lat();
            pin.coordinates.lng = event.latLng.lng();

            pin.mapsUrl = 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(event.latLng.lat() + ',' + event.latLng.lng());

            $scope.saveSelectedPin();
        };

        $scope.showPinDetails = function (event, pin, marker) {
            // marker will only be defined if this is called from pinDropEventHandler

            // pin must come straight out of the firebase array, otherwise the binding breaks
            // don't try to get it from the marker's 'data' property, even though it appears to be the same object (the Angular expression interpolation somehow breaks it?)
            $scope.selectedPin = pin;
            map.setCenter({lat: pin.coordinates.lat, lng: pin.coordinates.lng});
            map.showInfoWindow('pinDetails', marker || this); // this = the marker object in this context, if called by on-click
        };

        $scope.saveSelectedPin = function () {
            $scope.pins.$save($scope.selectedPin);
        };

        $scope.deletePin = function () {
            $scope.selectedPin['deleted'] = true;
            $scope.saveSelectedPin();
        };

        $scope.navigateToEditFarm = function () {
            if (MissionSvc.currentMission.use_fields) return;
            $state.go('mission-set-coordinates', ({mission_id: MissionSvc.currentMission.id}))
        };

        const setAvailableImagesForMissionDate = function (missionDate) {
            $scope.availableImagesForMissionDate = $scope.missionMapImagesArray.imagesForMissionDate(missionDate);

            $scope.availableImagesForMissionDate.sort((a, b) => {
                return $scope.mapTypes[a.data.type].priority - $scope.mapTypes[b.data.type].priority;
            });

            return $scope.availableImagesForMissionDate;
        };

        const missionMapImagesChanged = function () {
            $scope.showDownloadingImageryMessage = ($scope.missionMapImagesArray.length < 11);
            $scope.missionDates = $scope.missionMapImagesArray.uniqueMissionDates();
        };

        $scope.setMissionDate = function (missionDate, fromClick = false) {
            if (fromClick && missionDate.equals($scope.selectedMissionDate)) {
                $scope.selectedMissionDate = null;
                setAvailableImagesForMissionDate(null);
                $scope.$broadcast('setMapOverlay', null, '');
                return;
            }

            $scope.selectedMissionDate = missionDate;

            setAvailableImagesForMissionDate($scope.selectedMissionDate);

            const currentMissionImage = $scope.selectedMissionImage || $scope.availableImagesForMissionDate[0];
            const matchedMissionImages = $scope.availableImagesForMissionDate.filter((item, idx, arr) => {
                return item.data.type == currentMissionImage.data.type;
            });

            const newMissionImage = matchedMissionImages[0] || $scope.availableImagesForMissionDate[0];
            $scope.setMissionImage(newMissionImage);
        };

        const getBoundsForMissionImage = function (missionImage) {
            let imageBounds = new google.maps.LatLngBounds();
            missionImage.data.coordinates.forEach((item) => {
                imageBounds.extend(new google.maps.LatLng(parseFloat(item.lat), parseFloat(item.lng)));
            });
            return imageBounds;
        };

        $scope.setMissionImage = function (missionImage) {
            $scope.selectedMissionImage = missionImage;

            if ($scope.selectedMissionImage) {

                if ($scope.selectedMissionImage.data.coordinates
                    && typeof $scope.selectedMissionImage.data.coordinates['length'] === 'number'
                    && $scope.selectedMissionImage.data.coordinates.length > 0
                    && $scope.selectedMissionImage.data.url) {

                    //we can assume these are images rendered from tiles

                    //calculate the bounds of the image
                    //this is done because the bounds of the image and those of the farm may not be the same

                    $scope.$broadcast('setMapTileOverlay', getBoundsForMissionImage($scope.selectedMissionImage), $scope.selectedMissionImage.data.url);

                } else {
                    // this is a png image
                    $scope.$broadcast('setMapOverlay', bounds, $scope.selectedMissionImage.data.url);
                }

            }
            else {
                $scope.$broadcast('setMapOverlay', null, '');
            }
        };

        const coordinatesLoaded = function (snapshot) {
            const coordinates = snapshot.val();
            bounds = new google.maps.LatLngBounds();
            Object.keys(coordinates).forEach((item) => {
                bounds.extend(new google.maps.LatLng(parseFloat(coordinates[item].lat), parseFloat(coordinates[item].lng)));
            });

            map.fitBounds(bounds);
            map.setZoom(15);
        };

        $scope.init = function () {
            MissionSvc.getSelectedMission($scope.missionId);

            $scope.missionMapImagesArray = MissionMapImageList(firebase.database().ref(`mission_map_images/${$scope.missionId}/images`));
            $scope.missionMapImagesArray.$watch(missionMapImagesChanged);

            const initPromises = [
                retrieveMapLayerTypesFromFirebase(),
                retrieveAcquisitionTypesFromFirebase(),
                NgMap.getMap('mapDiv').then((ngmap) => {
                    map = ngmap;
                }),
                retrieveCoordinatesFromFirebase(),
                $scope.missionMapImagesArray.$loaded(),
            ];

            Promise.all(initPromises).then(results => {
                coordinatesLoaded(results[3]);
                if (!$scope.initLoad) {
                    $scope.setMissionDate($scope.missionDates[0]);
                    $scope.setMissionImage($scope.availableImagesForMissionDate[0]);
                }
                $scope.showDownloadingImageryMessage = ($scope.missionMapImagesArray.length < 11);

            }).then(() => {
                $scope.$apply();
            });

            if (angular.isDefined(TogetherJS)) {
                TogetherJS.on("ready", TogetherJS_OnReady);
                TogetherJS.on("close", TogetherJS_OnClose);

                // hook up listeners for TogetherJS messages, see TogetherJS_OnReady
                // and https://togetherjs.com/docs/#communication-channel
                TogetherJS.hub.on('centerChanged', TogetherJS_OnMsgCenterChanged);
                TogetherJS.hub.on('zoomChanged', TogetherJS_OnMsgZoomChanged);
                // sync state for later comers to a session
                TogetherJS.hub.on('togetherjs.hello', TogetherJS_OnClientJoinedSession);
            }

            $scope.setMissionDate($scope.missionDates[$scope.missionMapImagesArray.length]);
        };

        $scope.init();
    });
