angular.module('farmpin')
    .factory('MissionMapImage', function($firebaseUtils) {
        function MissionMapImage(snapshot){
            // store the record id so AngularFire can identify it
            this.$id = snapshot.key;

            // apply the data
            this.update(snapshot);
        }

        MissionMapImage.prototype = {
            update: function(snapshot) {
              const oldData = angular.extend({}, this.data);

              // apply changes to this.data instead of directly on `this`
              this.data = snapshot.val();

              // add a parsed date to our widget
              this._imageDate = new Date(this.data.date);

              // determine if anything changed, note that angular.equals will not check
              // $value or $priority (since it excludes anything that starts with $)
              // so be careful when using angular.equals()
              return !angular.equals(this.data, oldData);
            },

            getImageDate: function() {
              return this._imageDate;
            },

            toJSON: function() {
              // since we changed where our data is stored, we need to tell AngularFire how
              // to get the JSON version of it. We can use $firebaseUtils.toJSON() to remove
              // private variables, copy the data into a shippable format, and do validation
              return $firebaseUtils.toJSON(this.data);
            }
          };

          return MissionMapImage;
    });


angular.module('farmpin')
    .factory("MissionMapImageFactory", function($firebaseArray, MissionMapImage) {
        return $firebaseArray.$extend({
            // change the added behavior to return Widget objects
            $$added: function(snapshot) {
                // instead of creating the default POJO (plain old JavaScript object)
                // we will return an instance of the Widget class each time a child_added
                // event is received from the server
                return new MissionMapImage(snapshot);
            },

            // override the update behavior to call MissionMapImage.update()
            $$updated: function(snapshot) {
                // we need to return true/false here or $watch listeners will not get triggered
                // luckily, our Widget.prototype.update() method already returns a boolean if
                // anything has changed
                return this.$getRecord(snapshot.key).update(snapshot);
            },

            imagesForMissionDate: function(missionDate) {
                if (missionDate === null || angular.isUndefined(missionDate)){
                    return [];
                }

                return this.$list.filter((item) => {
                    return item.getImageDate().getTime() === missionDate.imageDate.getTime() && item.data.acquisition_type === missionDate.imageSource;
                });
            },

            uniqueMissionDates: function() {
                //extract UNIQUE dates from the array
                const dateSet = new Set();
                const availableDates = [];

                angular.forEach(this.$list, (item) => {
                    const key = item.getImageDate().getTime().toString()+item.data.acquisition_type;
                    if (!dateSet.has(key)) {
                        availableDates.push(new MissionDate(item.getImageDate(), item.data.acquisition_type));
                    }
                    dateSet.add(key);
                });

                availableDates.sort((a, b) => {
                    return b.imageSource - a.imageSource;
                });

                availableDates.sort((a, b) => {
                    return b.imageDate - a.imageDate;
                });

                return availableDates;
            }
        });
});


angular.module('farmpin')
    .factory("MissionMapImageList", function(MissionMapImageFactory) {
        return function(ref) {
            return new MissionMapImageFactory(ref);
        };
});
