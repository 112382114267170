'use strict';

var CAMPAIGN_1 = "mielie-health-map";
var CAMPAIGN_2 = "koring";
var CAMPAIGN_3 = "citrus";
var CAMPAIGN_4 = "mielies";
var CAMPAIGN_5 = "mielies2";
var NEW_ORDER = "new-order";
var MOISTURE_MAP_TYPE = "NDMI";
var PLANT_HEALTH_TYPE = "SAVI";

var ACQ_TYPE_SATELLITE = "satellite";
var ACQ_TYPE_DRONE = "drone";

angular.module('farmpin', ['ui.router', 'credit-cards', 'firebase', 'ngMap']).config(['$locationProvider', function ($locationProvider) {
    $locationProvider.html5Mode(true);
}]).config(function ($stateProvider, $urlRouterProvider) {

    $stateProvider.state('home', {
        url: '/',
        templateUrl: '/static/partials/home.html',
        controller: 'HomeController'
    }).state('home-german', {
        url: '/translations/german',
        templateUrl: '/static/partials/translations/german/home.html',
        controller: 'HomeController'
    }).state('irricheck', {
        url: '/land/irricheck',
        templateUrl: '/static/partials/irricheck.html',
        controller: 'HomeController'
    }).state("" + CAMPAIGN_1, {
        url: "/land/" + CAMPAIGN_1,
        templateUrl: "/static/partials/" + CAMPAIGN_1 + ".html",
        controller: 'HomeController'
    }).state("" + CAMPAIGN_2, {
        url: "/land/" + CAMPAIGN_2,
        templateUrl: "/static/partials/" + CAMPAIGN_2 + ".html",
        controller: 'HomeController'
    }).state("" + CAMPAIGN_3, {
        url: "/land/" + CAMPAIGN_3,
        templateUrl: "/static/partials/" + CAMPAIGN_3 + ".html",
        controller: 'HomeController'
    }).state("" + CAMPAIGN_4, {
        url: "/land/" + CAMPAIGN_4,
        templateUrl: "/static/partials/" + CAMPAIGN_4 + ".html",
        controller: 'HomeController'
    }).state("" + CAMPAIGN_5, {
        url: "/land/" + CAMPAIGN_5,
        templateUrl: '/static/partials/mielies_b.html',
        controller: 'HomeController'
    }).state('campaign-get-started', {
        url: '/get-started',
        templateUrl: "/static/partials/campaign-form.html",
        controller: 'HomeController'
    }).state("" + NEW_ORDER, {
        url: '/order',
        templateUrl: '/static/partials/order.html',
        controller: 'HomeController'
    }).state('mission', {
        url: '/mission/:mission_id/progress',
        templateUrl: '/static/partials/mission.html',
        controller: 'MissionController'
    }).state('expert', {
        url: '/mission/:mission_id/progress/expert',
        templateUrl: '/static/partials/expert.html',
        controller: 'MissionController'
    }).state('pilot', {
        url: '/mission/:mission_id/progress/pilot',
        templateUrl: '/static/partials/pilot.html',
        controller: 'MissionController'
    }).state('processor', {
        url: '/mission/:mission_id/progress/processor',
        templateUrl: '/static/partials/processor.html',
        controller: 'MissionController'
    }).state('mission-set-coordinates', {
        url: '/mission/:mission_id/set-coordinates/:campaign',
        templateUrl: '/static/partials/map.html',
        controller: 'MapController'
    }).state('mission-set-coordinates-done', {
        url: '/mission/:mission_id/set-coordinates-done/:campaign',
        templateUrl: '/static/partials/next-steps.html',
        controller: 'NextStepsController'
    }).state('product-comparison', {
        url: '/product-comparison',
        templateUrl: '/static/partials/product-comparison.html',
        controller: 'HomeController'
    }).state('crop-insight', {
        url: '/crop-insight',
        templateUrl: '/static/partials/crop-insight.html',
        controller: 'HomeController'
    }).state('purchase', {
        url: '/mission/:mission_id/purchase',
        templateUrl: '/static/partials/pay.html',
        controller: 'PayController'
    }).state('pay-response', {
        url: '/mission/:mission_id/payment-response?id&resourcePath',
        templateUrl: '/static/partials/pay_response.html',
        controller: 'PayResponseController'
    }).state('example-report', {
        url: '/example-report',
        templateUrl: '/static/partials/example-report.html',
        controller: 'HomeController'
    })

    /*******FIELD MAP IMAGES ROUTES*********/

    .state('field-map-images', {
        url: '/farm/:mission_id',
        templateUrl: '/static/partials/field_map_images.html',
        controller: 'FieldMapImagesController',
        params: {
            initLoad: false
        }
    });

    // catch all route
    // send users to the form page
    $urlRouterProvider.otherwise('/');
});
'use strict';

angular.module('farmpin').factory('MissionMapImage', function ($firebaseUtils) {
    function MissionMapImage(snapshot) {
        // store the record id so AngularFire can identify it
        this.$id = snapshot.key;

        // apply the data
        this.update(snapshot);
    }

    MissionMapImage.prototype = {
        update: function update(snapshot) {
            var oldData = angular.extend({}, this.data);

            // apply changes to this.data instead of directly on `this`
            this.data = snapshot.val();

            // add a parsed date to our widget
            this._imageDate = new Date(this.data.date);

            // determine if anything changed, note that angular.equals will not check
            // $value or $priority (since it excludes anything that starts with $)
            // so be careful when using angular.equals()
            return !angular.equals(this.data, oldData);
        },

        getImageDate: function getImageDate() {
            return this._imageDate;
        },

        toJSON: function toJSON() {
            // since we changed where our data is stored, we need to tell AngularFire how
            // to get the JSON version of it. We can use $firebaseUtils.toJSON() to remove
            // private variables, copy the data into a shippable format, and do validation
            return $firebaseUtils.toJSON(this.data);
        }
    };

    return MissionMapImage;
});

angular.module('farmpin').factory("MissionMapImageFactory", function ($firebaseArray, MissionMapImage) {
    return $firebaseArray.$extend({
        // change the added behavior to return Widget objects
        $$added: function $$added(snapshot) {
            // instead of creating the default POJO (plain old JavaScript object)
            // we will return an instance of the Widget class each time a child_added
            // event is received from the server
            return new MissionMapImage(snapshot);
        },

        // override the update behavior to call MissionMapImage.update()
        $$updated: function $$updated(snapshot) {
            // we need to return true/false here or $watch listeners will not get triggered
            // luckily, our Widget.prototype.update() method already returns a boolean if
            // anything has changed
            return this.$getRecord(snapshot.key).update(snapshot);
        },

        imagesForMissionDate: function imagesForMissionDate(missionDate) {
            if (missionDate === null || angular.isUndefined(missionDate)) {
                return [];
            }

            return this.$list.filter(function (item) {
                return item.getImageDate().getTime() === missionDate.imageDate.getTime() && item.data.acquisition_type === missionDate.imageSource;
            });
        },

        uniqueMissionDates: function uniqueMissionDates() {
            //extract UNIQUE dates from the array
            var dateSet = new Set();
            var availableDates = [];

            angular.forEach(this.$list, function (item) {
                var key = item.getImageDate().getTime().toString() + item.data.acquisition_type;
                if (!dateSet.has(key)) {
                    availableDates.push(new MissionDate(item.getImageDate(), item.data.acquisition_type));
                }
                dateSet.add(key);
            });

            availableDates.sort(function (a, b) {
                return b.imageSource - a.imageSource;
            });

            availableDates.sort(function (a, b) {
                return b.imageDate - a.imageDate;
            });

            return availableDates;
        }
    });
});

angular.module('farmpin').factory("MissionMapImageList", function (MissionMapImageFactory) {
    return function (ref) {
        return new MissionMapImageFactory(ref);
    };
});
'use strict';

angular.module('farmpin').controller('FieldMapImagesController', function ($scope, $state, $window, $firebaseArray, MissionSvc, NgMap, MissionMapImageList) {

    $scope.missionId = $state.params.mission_id;
    $scope.initLoad = $state.params.initLoad;
    $scope.missionSvc = MissionSvc;
    $scope.mapTypes = {};
    $scope.acquisitionTypes = {};
    $scope.liveShareStarting = false;
    $scope.liveShareActive = false;
    $scope.showDownloadingImageryMessage = true;
    $scope.addPinModeActive = false;
    $scope.selectedPin = null;

    $scope.missionMapImagesArray = [];
    $scope.missionDates = [];
    $scope.selectedMissionDate = null;
    $scope.selectedMissionImage = null;
    $scope.availableImagesForMissionDate = [];

    $scope.pinCropIssues = [{
        name: 'Soil',
        url: '/static/img/markers/Crop_Issue_Soil.svg',
        scaledSize: [40, 55],
        labelOrigin: [20, 35]
    }, {
        name: 'Pests',
        url: '/static/img/markers/Crop_Issue_Pests.svg',
        scaledSize: [40, 55],
        labelOrigin: [20, 35]
    }, {
        name: 'Diseases',
        url: '/static/img/markers/Crop_Issue_Diseases.png',
        scaledSize: [40, 55],
        labelOrigin: [20, 35]
    }, {
        name: 'Water',
        url: '/static/img/markers/Crop_Issue_Water.png',
        scaledSize: [40, 55],
        labelOrigin: [20, 35]
    }, {
        name: 'Fertiliser',
        url: '/static/img/markers/Crop_Issue_Fertiliser.png',
        scaledSize: [40, 55],
        labelOrigin: [20, 35]
    }, {
        name: 'Mechanical',
        url: '/static/img/markers/Crop_Issue_Mechanical.png',
        scaledSize: [40, 55],
        labelOrigin: [20, 35]
    }, {
        name: 'Weather',
        url: '/static/img/markers/Crop_Issue_Weather.png',
        scaledSize: [40, 55],
        labelOrigin: [20, 35]
    }, {
        name: 'Not sure',
        url: '/static/img/markers/Crop_Issue_Not_Sure.png',
        scaledSize: [40, 55],
        labelOrigin: [20, 35],
        default: true
    }];

    var lowPriorityMarkerType = {
        name: 'Low priority',
        url: '/static/img/markers/Small_Pin.png',
        scaledSize: [30, 32],
        labelOrigin: [15, 15]
    };

    $scope.iconObjectFromPin = function (pin) {
        // {url:'/static/img/markers/Crop_Issue_Not_Sure.png', scaledSize:[40,55], labelOrigin:[20,35]}
        if (!pin.highPriority) {
            return lowPriorityMarkerType;
        }

        var result = $scope.pinCropIssues.filter(function (obj) {
            return obj.name === pin.markerType;
        });

        if (result.length > 0) {
            return result[0];
        }

        return defaultMarkerType();
    };

    var defaultMarkerType = function defaultMarkerType() {
        var result = $scope.pinCropIssues.filter(function (obj) {
            return obj.hasOwnProperty('default') && obj.default === true;
        });

        if (result.length > 0) {
            return result[0];
        }

        return {
            name: 'Not sure',
            url: '/static/img/markers/Crop_Issue_Not_Sure.png',
            scaledSize: [40, 55],
            labelOrigin: [20, 35]
        };
    };

    var firebasePinsRef = firebase.database().ref('mission_map_images/' + $scope.missionId + '/pins');
    $scope.pins = $firebaseArray(firebasePinsRef);
    $scope.showPins = true;

    var shouldSendLiveShareLinkToAdmin = false;
    var centerChangedFromRemote = false;
    var zoomChangedFromRemote = false;

    var map = {};
    var bounds = void 0;
    var savi_index = '/static/img/savi.svg';
    var ndmi_index = '/static/img/Moisture.svg';
    var health_index = '/static/img/Health.svg';

    //polygon.getbounds() doesn't exist in Maps V3, for some ridiculous reason!
    if (!google.maps.Polygon.prototype.getBounds) {

        google.maps.Polygon.prototype.getBounds = function () {
            var newBounds = new google.maps.LatLngBounds();

            if (this.getPath) {
                console.log('getPath chosen');
                this.getPath().forEach(function (element, index) {
                    newBounds.extend(element);
                });
            } else if (this.getPaths) {
                console.log('getPaths chosen');
                this.getPaths()[0].forEach(function (element, index) {
                    newBounds.extend(element);
                });
            } else {
                console.error("Neither the 'getPath' nor the 'getPaths' function is defined on google.maps.Polygon.prototype - can't determine bounds!");
            }

            return newBounds;
        };
    }

    $scope.reloadWindow = function () {
        $window.location.reload();
    };

    $scope.showColorCodeIndex = function () {
        if ($scope.selectedMissionImage.data.type === 'SAVI') {
            return savi_index;
        } else if ($scope.selectedMissionImage.data.type === 'NDMI') {
            return ndmi_index;
        } else if ($scope.selectedMissionImage.data.type === 'NDVI-LATE' || $scope.selectedMissionImage.data.type === 'NDVI-EARLY') {
            return health_index;
        }
    };

    $scope.toggleLiveShare = function () {
        if (!$scope.liveShareActive) {
            $scope.liveShareStarting = true;
            shouldSendLiveShareLinkToAdmin = true;
        }
        TogetherJS(); // starts or stops a TogetherJS session
    };

    var TogetherJS_OnReady = function TogetherJS_OnReady() {
        console.log('TogetherJS ready');

        if (shouldSendLiveShareLinkToAdmin) {
            MissionSvc.sendLiveShareLinkToAdmin(TogetherJS.shareUrl());
            shouldSendLiveShareLinkToAdmin = false;
        }
        $scope.$apply(function () {
            $scope.liveShareStarting = false;
            $scope.liveShareActive = true;
        });

        // hook up listeners to sync panning and zooming across TogetherJS sessions
        map.addListener('center_changed', map_OnCenterChanged);
        map.addListener('zoom_changed', map_OnZoomChanged);
    };

    var TogetherJS_OnClose = function TogetherJS_OnClose() {
        console.log('TogetherJS close');
        $scope.$apply(function () {
            $scope.liveShareActive = false;
        });
    };

    var TogetherJS_OnMsgCenterChanged = function TogetherJS_OnMsgCenterChanged(msg) {
        if (!msg.sameUrl) {
            // ignore messages from different URLs
            return;
        }

        centerChangedFromRemote = true;
        try {
            map.setCenter(msg.value);
        } finally {
            centerChangedFromRemote = false;
        }
    };

    var TogetherJS_OnMsgZoomChanged = function TogetherJS_OnMsgZoomChanged(msg) {
        if (!msg.sameUrl) {
            // ignore messages from different URLs
            return;
        }

        zoomChangedFromRemote = true;
        try {
            map.setZoom(msg.value);
        } finally {
            zoomChangedFromRemote = false;
        }
    };

    var TogetherJS_OnClientJoinedSession = function TogetherJS_OnClientJoinedSession(msg) {
        if (!msg.sameUrl) {
            // ignore messages from different URLs
            return;
        }

        map_OnCenterChanged();
        map_OnZoomChanged();
    };

    var map_OnCenterChanged = function map_OnCenterChanged() {
        if (centerChangedFromRemote) {
            // ignore events that are raised as a result of TogetherJS_OnMsgCenterChanged
            return;
        }
        var newCenter = map.getCenter();
        TogetherJS.send({ type: 'centerChanged', value: newCenter.toJSON() });
    };

    var map_OnZoomChanged = function map_OnZoomChanged() {
        if (zoomChangedFromRemote) {
            // ignore events that are raised as a result of TogetherJS_OnMsgZoomChanged
            return;
        }
        var newZoom = map.getZoom();
        TogetherJS.send({ type: 'zoomChanged', value: newZoom });
    };

    var retrieveMapLayerTypesFromFirebase = function retrieveMapLayerTypesFromFirebase() {

        return new Promise(function (resolve, reject) {

            firebase.database().ref('map_layer_types').orderByChild('priority').on('value', function (snapshot) {

                var mapTypes = {};
                snapshot.forEach(function (child) {
                    mapTypes[child.key] = child.val();
                });

                $scope.mapTypes = mapTypes;
                resolve();
            });
        });
    };

    var retrieveAcquisitionTypesFromFirebase = function retrieveAcquisitionTypesFromFirebase() {
        return firebase.database().ref('acquisition_types').once('value').then(function (snapshot) {
            $scope.acquisitionTypes = snapshot.val();
        });
    };

    var retrieveCoordinatesFromFirebase = function retrieveCoordinatesFromFirebase() {
        return firebase.database().ref('mission_map_images/' + $scope.missionId + '/coordinates').once('value');
    };

    var getDefaultAcquisitionType = function getDefaultAcquisitionType() {
        var defaultAcquisitionType = null;

        Object.keys($scope.acquisitionTypes).forEach(function (key) {
            var item = $scope.acquisitionTypes[key];
            if (item.hasOwnProperty('default') && item.default) {
                defaultAcquisitionType = item;
            }
        });

        return defaultAcquisitionType;
    };

    $scope.getMissionDateIcon = function (item) {
        var acquisitionType = $scope.acquisitionTypes[item.imageSource] || getDefaultAcquisitionType();

        if (item.equals($scope.selectedMissionDate)) {
            return acquisitionType['selected_img'];
        } else {
            return acquisitionType['default_img'];
        }
    };

    $scope.pinDisplayFilter = function (value, index, array) {
        if (value.deleted == true) {
            return false;
        }
        if (!$scope.selectedMissionImage) {
            return false;
        }

        return (!value.hasOwnProperty('startDate') || new Date(value.startDate).getTime() <= $scope.selectedMissionImage.getImageDate().getTime()) && (!value.hasOwnProperty('endDate') || $scope.selectedMissionDate.getImageDate().getTime() <= new Date(value.endDate).getTime());
    };

    $scope.pinsForDate = function (imageDate) {
        return $scope.pins.filter(function (value, index, array) {
            if (value.deleted == true) {
                return false;
            }

            return (!value.hasOwnProperty('startDate') || new Date(value.startDate).getTime() <= imageDate.getTime()) && (!value.hasOwnProperty('endDate') || imageDate.getTime() <= new Date(value.endDate).getTime());
        }).length;
    };

    var highestPinDisplayId = function highestPinDisplayId(pinArray) {
        var highest = pinArray.reduce(function (acc, cur, index, array) {
            return acc > cur.displayId ? acc : cur.displayId;
        }, 0);

        return highest || 0;
    };

    var pinDropEventHandler = function pinDropEventHandler(event) {
        var defaultMarkerTypeForPin = defaultMarkerType();

        var pin = {
            coordinates: {
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            },
            startDate: moment($scope.selectedMissionImage.getImageDate()).format("YYYY-MM-DD"),
            markerType: defaultMarkerTypeForPin.name,
            missionId: $scope.missionId,
            resolved: false,
            addedToLayer: $scope.selectedMissionImage.data.type,
            description: '',
            createdAt: new Date().toISOString(),
            // https://developers.google.com/maps/documentation/urls/guide
            mapsUrl: 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(event.latLng.lat() + ',' + event.latLng.lng()),
            deleted: false,
            highPriority: true,
            displayId: highestPinDisplayId($scope.pins) + 1
        };

        $scope.pins.$add(pin).then(function (ref) {
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = Object.keys(map.markers)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var key = _step.value;

                    if (map.markers[key].data.$id === ref.key) {
                        $scope.showPinDetails(null, $scope.pins.$getRecord(ref.key), map.markers[key]); // have to use $getRecord, don't try to pass pin directly
                        break;
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }
        });

        $scope.toggleAddPinMode();
    };

    var mapClickListener = void 0;

    $scope.toggleAddPinMode = function () {
        // we need date information for the pin
        if (!$scope.addPinModeActive && $scope.selectedMissionDate) {
            $scope.addPinModeActive = true;
            map.setOptions({
                draggableCursor: 'url(static/img/markers/Add_Pin.svg) 20 58, crosshair'
            });
            mapClickListener = google.maps.event.addListenerOnce(map, 'click', pinDropEventHandler);
        } else {
            $scope.addPinModeActive = false;
            // revert to google maps default which is NOT the same as 'grab'
            map.setOptions({
                draggableCursor: ''
            });
            // this is needed because even though addListenerOnce is used above, the event will not have fired if you click cancel
            mapClickListener.remove();
        }
    };

    $scope.togglePinsVisible = function () {
        map.hideInfoWindow('pinDetails');
    };

    $scope.imageTypeButtonClass = function () {

        //calculates the class for the button parent, based on the number of available images for the selected date
        //relevant for media queries - if there are more image types, the button parent needs to be hidden at a greater screen width
        var i = $scope.availableImagesForMissionDate.length;

        if (i <= 3) {
            return "availableImageButtonParent3";
        }
        if (i <= 6) {
            return "availableImageButtonParent6";
        }
        return "availableImageButtonParent9";
    };

    $scope.imageTypeDropdownClass = function () {

        //calculates the class for the dropdown parent, based on the number of available images for the selected date
        //relevant for media queries - if there are more image types, the dropdown needs to be shown at a greater screen width
        var i = $scope.availableImagesForMissionDate.length;

        if (i <= 3) {
            return "availableImageDropdownParent3";
        }
        if (i <= 6) {
            return "availableImageDropdownParent6";
        }
        return "availableImageDropdownParent9";
    };

    $scope.onPinDragStart = function (event, pin, marker) {
        // pin must come straight out of the firebase array, otherwise the binding breaks
        // don't try to get it from the marker's 'data' property, even though it appears to be the same object (the Angular expression interpolation somehow breaks it?)
        $scope.selectedPin = pin;
        map.hideInfoWindow('pinDetails', marker || this); // this = the marker object in this context, if called by on-dragstart

        pin.coordinates.lat = event.latLng.lat();
        pin.coordinates.lng = event.latLng.lng();

        pin.mapsUrl = 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(event.latLng.lat() + ',' + event.latLng.lng());
    };

    $scope.onPinDrag = function (event, pin, marker) {
        pin.coordinates.lat = event.latLng.lat();
        pin.coordinates.lng = event.latLng.lng();

        pin.mapsUrl = 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(event.latLng.lat() + ',' + event.latLng.lng());
    };

    $scope.onPinDragEnd = function (event, pin, marker) {
        pin.coordinates.lat = event.latLng.lat();
        pin.coordinates.lng = event.latLng.lng();

        pin.mapsUrl = 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(event.latLng.lat() + ',' + event.latLng.lng());

        $scope.saveSelectedPin();
    };

    $scope.showPinDetails = function (event, pin, marker) {
        // marker will only be defined if this is called from pinDropEventHandler

        // pin must come straight out of the firebase array, otherwise the binding breaks
        // don't try to get it from the marker's 'data' property, even though it appears to be the same object (the Angular expression interpolation somehow breaks it?)
        $scope.selectedPin = pin;
        map.setCenter({ lat: pin.coordinates.lat, lng: pin.coordinates.lng });
        map.showInfoWindow('pinDetails', marker || this); // this = the marker object in this context, if called by on-click
    };

    $scope.saveSelectedPin = function () {
        $scope.pins.$save($scope.selectedPin);
    };

    $scope.deletePin = function () {
        $scope.selectedPin['deleted'] = true;
        $scope.saveSelectedPin();
    };

    $scope.navigateToEditFarm = function () {
        if (MissionSvc.currentMission.use_fields) return;
        $state.go('mission-set-coordinates', { mission_id: MissionSvc.currentMission.id });
    };

    var setAvailableImagesForMissionDate = function setAvailableImagesForMissionDate(missionDate) {
        $scope.availableImagesForMissionDate = $scope.missionMapImagesArray.imagesForMissionDate(missionDate);

        $scope.availableImagesForMissionDate.sort(function (a, b) {
            return $scope.mapTypes[a.data.type].priority - $scope.mapTypes[b.data.type].priority;
        });

        return $scope.availableImagesForMissionDate;
    };

    var missionMapImagesChanged = function missionMapImagesChanged() {
        $scope.showDownloadingImageryMessage = $scope.missionMapImagesArray.length < 11;
        $scope.missionDates = $scope.missionMapImagesArray.uniqueMissionDates();
    };

    $scope.setMissionDate = function (missionDate) {
        var fromClick = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        if (fromClick && missionDate.equals($scope.selectedMissionDate)) {
            $scope.selectedMissionDate = null;
            setAvailableImagesForMissionDate(null);
            $scope.$broadcast('setMapOverlay', null, '');
            return;
        }

        $scope.selectedMissionDate = missionDate;

        setAvailableImagesForMissionDate($scope.selectedMissionDate);

        var currentMissionImage = $scope.selectedMissionImage || $scope.availableImagesForMissionDate[0];
        var matchedMissionImages = $scope.availableImagesForMissionDate.filter(function (item, idx, arr) {
            return item.data.type == currentMissionImage.data.type;
        });

        var newMissionImage = matchedMissionImages[0] || $scope.availableImagesForMissionDate[0];
        $scope.setMissionImage(newMissionImage);
    };

    var getBoundsForMissionImage = function getBoundsForMissionImage(missionImage) {
        var imageBounds = new google.maps.LatLngBounds();
        missionImage.data.coordinates.forEach(function (item) {
            imageBounds.extend(new google.maps.LatLng(parseFloat(item.lat), parseFloat(item.lng)));
        });
        return imageBounds;
    };

    $scope.setMissionImage = function (missionImage) {
        $scope.selectedMissionImage = missionImage;

        if ($scope.selectedMissionImage) {

            if ($scope.selectedMissionImage.data.coordinates && typeof $scope.selectedMissionImage.data.coordinates['length'] === 'number' && $scope.selectedMissionImage.data.coordinates.length > 0 && $scope.selectedMissionImage.data.url) {

                //we can assume these are images rendered from tiles

                //calculate the bounds of the image
                //this is done because the bounds of the image and those of the farm may not be the same

                $scope.$broadcast('setMapTileOverlay', getBoundsForMissionImage($scope.selectedMissionImage), $scope.selectedMissionImage.data.url);
            } else {
                // this is a png image
                $scope.$broadcast('setMapOverlay', bounds, $scope.selectedMissionImage.data.url);
            }
        } else {
            $scope.$broadcast('setMapOverlay', null, '');
        }
    };

    var coordinatesLoaded = function coordinatesLoaded(snapshot) {
        var coordinates = snapshot.val();
        bounds = new google.maps.LatLngBounds();
        Object.keys(coordinates).forEach(function (item) {
            bounds.extend(new google.maps.LatLng(parseFloat(coordinates[item].lat), parseFloat(coordinates[item].lng)));
        });

        map.fitBounds(bounds);
        map.setZoom(15);
    };

    $scope.init = function () {
        MissionSvc.getSelectedMission($scope.missionId);

        $scope.missionMapImagesArray = MissionMapImageList(firebase.database().ref('mission_map_images/' + $scope.missionId + '/images'));
        $scope.missionMapImagesArray.$watch(missionMapImagesChanged);

        var initPromises = [retrieveMapLayerTypesFromFirebase(), retrieveAcquisitionTypesFromFirebase(), NgMap.getMap('mapDiv').then(function (ngmap) {
            map = ngmap;
        }), retrieveCoordinatesFromFirebase(), $scope.missionMapImagesArray.$loaded()];

        Promise.all(initPromises).then(function (results) {
            coordinatesLoaded(results[3]);
            if (!$scope.initLoad) {
                $scope.setMissionDate($scope.missionDates[0]);
                $scope.setMissionImage($scope.availableImagesForMissionDate[0]);
            }
            $scope.showDownloadingImageryMessage = $scope.missionMapImagesArray.length < 11;
        }).then(function () {
            $scope.$apply();
        });

        if (angular.isDefined(TogetherJS)) {
            TogetherJS.on("ready", TogetherJS_OnReady);
            TogetherJS.on("close", TogetherJS_OnClose);

            // hook up listeners for TogetherJS messages, see TogetherJS_OnReady
            // and https://togetherjs.com/docs/#communication-channel
            TogetherJS.hub.on('centerChanged', TogetherJS_OnMsgCenterChanged);
            TogetherJS.hub.on('zoomChanged', TogetherJS_OnMsgZoomChanged);
            // sync state for later comers to a session
            TogetherJS.hub.on('togetherjs.hello', TogetherJS_OnClientJoinedSession);
        }

        $scope.setMissionDate($scope.missionDates[$scope.missionMapImagesArray.length]);
    };

    $scope.init();
});
'use strict';

angular.module('farmpin').controller('GroundOverlayCtrl', function ($scope, $timeout, NgMap, preloader) {
    var instance = this;
    // there seems to me a multithread or other timing issue that meant an overlay reference could get
    // lost without keeping all of them in an array to make removal easier.
    instance.overlays = [];
    var mapMinZoom = 9;
    var mapMaxZoom = 20;
    instance.ngMap = {};
    instance.bounds = [];

    $scope.fetching_image = false;

    var setOverlay = function setOverlay(bounds, imageUrl) {
        while (instance.overlays[0]) {
            instance.overlays.pop().setMap(null);
        }

        if (!imageUrl || !bounds) {
            return;
        }

        // add new overlay
        NgMap.getMap().then(function (map) {

            if (instance.ngMap && instance.ngMap.overlayMapTypes) {
                instance.ngMap.overlayMapTypes.clear();
            }

            var overlay = new google.maps.GroundOverlay(imageUrl, bounds, { clickable: false });
            var fetching_image_timer = $timeout(function () {
                $scope.fetching_image = true;
            }, 500);
            preloader.preloadImages([imageUrl]).then(function () {
                $timeout.cancel(fetching_image_timer);
                $scope.fetching_image = false;
                overlay.setMap(map);
                instance.overlays.push(overlay);
            }, function () {
                $scope.fetching_image = false;
                console.log('load failed');
            });
        });
    };

    $scope.$on('setMapOverlay', function (event, bounds, imageUrl) {
        setOverlay(bounds, imageUrl);
    });

    $scope.$on('setMapTileOverlay', function (event, bounds, url) {
        instance.bounds = bounds;
        setTileOverlay(instance.bounds, url);
    });

    var setTileOverlay = function setTileOverlay(bounds, url) {

        while (instance.overlays[0]) {
            instance.overlays.pop().setMap(null);
        }

        if (!bounds) {
            return;
        }

        // add new overlay
        NgMap.getMap().then(function (map) {

            instance.ngMap = map;
            instance.ngMap.setCenter(instance.bounds.getCenter());
            instance.ngMap.fitBounds(instance.bounds);

            instance.ngMap.overlayMapTypes.clear();
            var pix4tiler = createOverlay(url);
            instance.ngMap.overlayMapTypes.insertAt(0, pix4tiler);
        });
    };

    function createOverlay(url) {
        return new google.maps.ImageMapType({

            getTileUrl: function getTileUrl(coord, zoom) {

                if (zoom < mapMinZoom || zoom > mapMaxZoom) {
                    return "http://www.maptiler.org/img/none.png";
                }

                //https://developers.google.com/maps/documentation/javascript/coordinates

                var mercantorProj = instance.ngMap.getProjection(); //converts the map's location into a 'world' coordinate

                var tileSize = 256 / Math.pow(2, zoom);
                // Assume a map at zoom level 0 is a single tile of the base tile size (256px).
                // We then define world coordinates relative to pixel coordinates at current zoom level,
                // using the map.getProjection() above to convert latitudes and longitudes to pixel positions on this base tile.

                var tileBounds = new google.maps.LatLngBounds(mercantorProj.fromPointToLatLng(new google.maps.Point(coord.x * tileSize, (coord.y + 1) * tileSize)), mercantorProj.fromPointToLatLng(new google.maps.Point((coord.x + 1) * tileSize, coord.y * tileSize)));

                if (tileBounds.intersects(instance.bounds) && zoom >= mapMinZoom && zoom <= mapMaxZoom) {
                    return url + '%2F' + zoom + '%2F' + coord.x + '%2F' + (Math.pow(2, zoom) - coord.y - 1) + '.png?alt=media';
                } else return "http://www.maptiler.org/img/none.png";
            }
        });
    }
});
"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MissionDate = function () {
    function MissionDate(imageDate, imageSource) {
        _classCallCheck(this, MissionDate);

        this.imageDate = imageDate;
        this.imageSource = imageSource;
    }

    _createClass(MissionDate, [{
        key: "equals",
        value: function equals(other) {
            if (other === null || angular.isUndefined(other)) {
                return false;
            }

            return this.imageDate.getTime() === other.imageDate.getTime() && this.imageSource === other.imageSource;
        }
    }]);

    return MissionDate;
}();
'use strict';

// Source: https://github.com/dabit3/angular-easy-image-preloader

angular.module('farmpin').factory('preloader', function ($q, $rootScope) {
    // I manage the preloading of image objects. Accepts an array of image URLs.
    function Preloader(imageLocations) {
        // I am the image SRC values to preload.
        this.imageLocations = imageLocations;
        // As the images load, we'll need to keep track of the load/error
        // counts when announing the progress on the loading.
        this.imageCount = this.imageLocations.length;
        this.loadCount = 0;
        this.errorCount = 0;
        // I am the possible states that the preloader can be in.
        this.states = {
            PENDING: 1,
            LOADING: 2,
            RESOLVED: 3,
            REJECTED: 4
        };
        // I keep track of the current state of the preloader.
        this.state = this.states.PENDING;
        // When loading the images, a promise will be returned to indicate
        // when the loading has completed (and / or progressed).
        this.deferred = $q.defer();
        this.promise = this.deferred.promise;
    }
    // ---
    // STATIC METHODS.
    // ---
    // I reload the given images [Array] and return a promise. The promise
    // will be resolved with the array of image locations.
    Preloader.preloadImages = function (imageLocations) {
        var preloader = new Preloader(imageLocations);
        return preloader.load();
    };
    // ---
    // INSTANCE METHODS.
    // ---
    Preloader.prototype = {
        // Best practice for "instnceof" operator.
        constructor: Preloader,
        // ---
        // PUBLIC METHODS.
        // ---
        // I determine if the preloader has started loading images yet.
        isInitiated: function isInitiated() {
            return this.state !== this.states.PENDING;
        },
        // I determine if the preloader has failed to load all of the images.
        isRejected: function isRejected() {
            return this.state === this.states.REJECTED;
        },
        // I determine if the preloader has successfully loaded all of the images.
        isResolved: function isResolved() {
            return this.state === this.states.RESOLVED;
        },
        // I initiate the preload of the images. Returns a promise.
        load: function load() {
            // If the images are already loading, return the existing promise.
            if (this.isInitiated()) {
                return this.promise;
            }
            this.state = this.states.LOADING;
            for (var i = 0; i < this.imageCount; i++) {
                this.loadImageLocation(this.imageLocations[i]);
            }
            // Return the deferred promise for the load event.
            return this.promise;
        },
        // ---
        // PRIVATE METHODS.
        // ---
        // I handle the load-failure of the given image location.
        handleImageError: function handleImageError(imageLocation) {
            this.errorCount++;
            // If the preload action has already failed, ignore further action.
            if (this.isRejected()) {
                return;
            }
            this.state = this.states.REJECTED;
            this.deferred.reject(imageLocation);
        },
        // I handle the load-success of the given image location.
        handleImageLoad: function handleImageLoad(imageLocation) {
            this.loadCount++;
            // If the preload action has already failed, ignore further action.
            if (this.isRejected()) {
                return;
            }
            // Notify the progress of the overall deferred. This is different
            // than Resolving the deferred - you can call notify many times
            // before the ultimate resolution (or rejection) of the deferred.
            this.deferred.notify({
                percent: Math.ceil(this.loadCount / this.imageCount * 100),
                imageLocation: imageLocation
            });
            // If all of the images have loaded, we can resolve the deferred
            // value that we returned to the calling context.
            if (this.loadCount === this.imageCount) {
                this.state = this.states.RESOLVED;
                this.deferred.resolve(this.imageLocations);
            }
        },
        // I load the given image location and then wire the load / error
        // events back into the preloader instance.
        // --
        // NOTE: The load/error events trigger a $digest.
        loadImageLocation: function loadImageLocation(imageLocation) {
            var preloader = this;
            // When it comes to creating the image object, it is critical that
            // we bind the event handlers BEFORE we actually set the image
            // source. Failure to do so will prevent the events from proper
            // triggering in some browsers.
            // --
            // The below removes a dependency on jQuery, based on a comment
            // on Ben Nadel's original blog by user Adriaan:
            // http://www.bennadel.com/members/11887-adriaan.htm
            var image = angular.element(new Image()).bind('load', function (event) {
                // Since the load event is asynchronous, we have to
                // tell AngularJS that something changed.
                $rootScope.$apply(function () {
                    preloader.handleImageLoad(event.target.src);
                    // Clean up object reference to help with the
                    // garbage collection in the closure.
                    preloader = image = event = null;
                });
            }).bind('error', function (event) {
                // Since the load event is asynchronous, we have to
                // tell AngularJS that something changed.
                $rootScope.$apply(function () {
                    preloader.handleImageError(event.target.src);
                    // Clean up object reference to help with the
                    // garbage collection in the closure.
                    preloader = image = event = null;
                });
            }).attr('src', imageLocation);
        }
    };
    // Return the factory instance.
    return Preloader;
});
'use strict';

angular.module('farmpin').controller('HomeController', function ($scope, $state, $window, MissionSvc) {
    $scope.formData = {};
    $scope.emailFormVisible = true;
    $scope.formSubmission = false;
    var campaign = "";

    $scope.initiateMission = function () {
        $scope.formSubmission = true;
        for (var item in $scope.formData) {
            if ($scope.formData[item].trim().length == 0) {
                return;
            }
        }

        // TODO: error handling
        MissionSvc.initiateMission($scope.formData).then(function (response) {
            $scope.emailFormVisible = false;
            $scope.formSubmission = false;
            var promise = MissionSvc.sendMissionDetailsToAdmin(response.data.id);

            $scope.trackLeadEvent();

            return promise;
        }).then(function (response) {
            return $state.transitionTo('mission-set-coordinates', { mission_id: response.data.id, campaign: campaign });
        });
    };

    $scope.trackLeadEvent = function () {
        if (angular.isDefined($window.fbq)) {
            try {
                $window.fbq('track', 'Lead');
            } catch (ex) {
                console.error(ex.message);
            }
        } else {
            console.warn('Facebook Pixel code not loaded');
        }

        if (angular.isDefined($window.ga)) {
            try {
                $window.ga('send', 'event', 'Button', 'Click', 'Order');
            } catch (ex) {
                console.error(ex.message);
            }
        } else {
            console.warn('Google Analytics code not loaded');
        }
    };

    $scope.init = function () {
        $scope.formData.name = '';
        $scope.formData.email = '';
        $scope.formData.cell = '';

        if ($state.current.name === CAMPAIGN_1) {
            campaign = CAMPAIGN_1;
        } else if ($state.current.name === CAMPAIGN_2) {
            campaign = CAMPAIGN_2;
        } else if ($state.current.name === CAMPAIGN_3) {
            campaign = CAMPAIGN_3;
        } else if ($state.current.name === NEW_ORDER) {
            campaign = NEW_ORDER;
        }
    };
    $scope.init();
});
'use strict';

angular.module('farmpin').controller('MapController', function ($scope, $state, $timeout, MapSvc, MissionSvc) {

    MapSvc.init();

    $scope.$state = $state;
    $scope.areaInHectares = 0;
    $scope.formSubmission = false;
    $scope.mapStep = 0;
    $scope.failedToGeoLocate = '';
    $scope.formatted_location = '';
    $scope.gettingLocation = false;
    var campaign = "";

    $scope.missionSvc = MissionSvc;

    var init = function init() {
        MissionSvc.getSelectedMission($state.params.mission_id).then(function (response) {
            if (angular.isArray(response.data.mission.coordinates) && response.data.mission.coordinates.length > 0) {
                $scope.findMyFields();
                jQuery('#savePolygonArea').show();
                MapSvc.loadExistingPolygon(response.data.mission.coordinates);
                $scope.areaInHectares = MapSvc.getPolygonAreaInHectares();
            }
        });

        if (!!$state.params.campaign) {
            campaign = $state.params.campaign;
        }
        setTitleAndDescription();
    };

    MapSvc.subscribeOverlayChangedEvent($scope, function polygonAreaChanged() {
        $scope.areaInHectares = MapSvc.getPolygonAreaInHectares();
        $scope.$apply();
        jQuery('#savePolygonArea').show();
    });

    MapSvc.subscribeAddressSelectedEvent($scope, function addressSelected() {
        jQuery('#savePolygonArea').hide();
    });

    $scope.$on('location-found', function (event, args) {
        $scope.formatted_location = args;
        $timeout(function () {
            $scope.mapStep = 1;
        }, 0);
    });

    function setTitleAndDescription() {

        switch ($state.params.campaign) {
            case "":
                $scope.success_title = "Where is your farm?";
                $scope.success_description = "Find the fields you want to see maps & graphs for.";
                break;
            // case CAMPAIGN_1:
            //     $scope.success_title = "Where is your farm?";
            //     $scope.success_description = "Find the fields where you want to pinpoint plant performance.";
            //     break;
            // case CAMPAIGN_2:
            //     $scope.success_title = "Where is your farm?";
            //     $scope.success_description = "Find the fields where you want to pinpoint plant performance.";
            //     break;
            // case CAMPAIGN_3:
            //     $scope.success_title = "Where is your farm?";
            //     $scope.success_description = "Find the fields where you want to pinpoint plant performance.";
            //     break;
            case NEW_ORDER:
                $scope.success_title = "Where is your farm?";
                $scope.success_description = "Find the fields you want to see maps & graphs for.";
                break;

            default:
                $scope.success_title = "Where is your farm?";
                $scope.success_description = "Find the fields you want to see maps & graphs for.";

        }
    }

    $scope.updateMission = function () {
        $scope.formSubmission = true;
        MissionSvc.updateMissionRequest(MapSvc.getPolygonCoordinates(), MapSvc.getPolygonArea()).then(function (response) {
            return MissionSvc.sendMissionDetailsToAdmin(response.data.id);
        }).then(function (response) {
            return $state.transitionTo('field-map-images', { mission_id: $state.params.mission_id, initLoad: true });
        });
    };

    // $scope.closeAdvisoryParent = function () {
    //     jQuery('#advisory-parent').hide();
    // };

    $scope.findMyFields = function () {
        jQuery('#thank-you-parent').toggle();
        jQuery('#actions-pain').toggle();
        jQuery('#polygon-parent').toggle();
        jQuery('#mapDiv').addClass('hidden-pseudo');
    };

    $scope.getMyLocation = function () {
        $scope.gettingLocation = true;
        MapSvc.getUserCurrentLocation().then(function () {
            $scope.gettingLocation = false;
            $scope.mapStep = 1;
        }).catch(function (error) {
            $scope.gettingLocation = false;
            return $scope.failedToGeoLocate = error;
        });
    };

    $scope.doNotFindMyFields = function () {
        $state.transitionTo('home');
    };

    $scope.clearPolygonArea = function () {
        MapSvc.clearPolygonCoordinates();
    };

    $scope.deleteSearchInput = function () {
        $scope.formatted_location = '';
    };

    init();
});
'use strict';

angular.module('farmpin').controller('NextStepsController', function ($scope, $state, MissionSvc) {

    $scope.$state = $state;
    $scope.campaignType = "";
    $scope.campaign1 = CAMPAIGN_1;
    $scope.campaign2 = CAMPAIGN_2;
    $scope.campaign3 = CAMPAIGN_3;
    $scope.new_order = NEW_ORDER;
    //$scope.payment_received = PAYMENT_RECEIVED;
    $scope.mission_id = $state.params.mission_id;

    var init = function init() {
        if (!MissionSvc.currentMission) {
            MissionSvc.getSelectedMission($state.params.mission_id);
        }
        if (!!$state.params.campaign) {
            $scope.campaignType = $state.params.campaign;
        }
    };

    $(document).ready(function () {
        $('.collapsible').collapsible();
    });

    init();
});
'use strict';

angular.module('farmpin').factory('MapSvc', function ($http, $q, $rootScope) {
    var latitude = -33.825834;
    var longitude = 18.588947;
    var map_zoom = 14;
    var polygonArea = 0;
    var polygonCoordinates;
    var Polygon;
    var mapOverlays = [];
    var drawingManager = null;

    return {
        map: null,
        searchLocationMarker: new google.maps.Marker(),
        mapOptions: {
            center: new google.maps.LatLng(latitude, longitude),
            zoom: map_zoom,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            mapTypeId: google.maps.MapTypeId.HYBRID
        },

        addMarker: function addMarker(marker, position, map) {
            marker.setPosition(position);
            marker.setMap(map);
        },

        subscribeOverlayChangedEvent: function subscribeOverlayChangedEvent(scope, callback) {
            var handler = $rootScope.$on('polygon-overlay-changed', callback);
            scope.$on('$destroy', handler);
        },

        subscribeAddressSelectedEvent: function subscribeAddressSelectedEvent(scope, callback) {
            var handler = $rootScope.$on('autocomplete-address-selected', callback);
            scope.$on('$destroy', handler);
        },

        init: function init() {
            var _this = this;

            var instance = this;

            this.map = new google.maps.Map(document.getElementById("mapDiv"), this.mapOptions);

            drawingManager = new google.maps.drawing.DrawingManager({
                drawingMode: google.maps.drawing.OverlayType.POLYGON,
                drawingControl: false,
                drawingControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_CENTER,
                    drawingModes: ['polygon']
                },
                polygonOptions: {
                    fillColor: '#F57713',
                    strokeColor: '#F57713',
                    clickable: true,
                    editable: true
                }
            });

            drawingManager.setMap(this.map);

            google.maps.event.addListener(drawingManager, "overlaycomplete", function (event) {
                mapOverlays.push(event);
                if (event.type != google.maps.drawing.OverlayType.MARKER) {
                    // Switch back to non-drawing mode after drawing a shape.
                    drawingManager.setDrawingMode(null);

                    var newShape = event.overlay;
                    polygonCoordinates = event.overlay.getPath().getArray();
                    newShape.type = event.type;
                    polygonArea = google.maps.geometry.spherical.computeArea(event.overlay.getPath());

                    google.maps.event.addListener(event.overlay.getPath(), 'insert_at', function () {
                        polygonArea = google.maps.geometry.spherical.computeArea(event.overlay.getPath());
                        $rootScope.$emit('polygon-overlay-changed');
                    });

                    google.maps.event.addListener(event.overlay.getPath(), 'set_at', function () {
                        polygonArea = google.maps.geometry.spherical.computeArea(event.overlay.getPath());
                        $rootScope.$emit('polygon-overlay-changed');
                    });

                    google.maps.event.addListener(newShape, 'rightclick', function (mev) {
                        if (mev.vertex != null) {
                            newShape.getPath().removeAt(mev.vertex);
                        }
                    });

                    $rootScope.$emit('polygon-overlay-changed');
                }
            });

            // add autocomplete to the address search textbox
            var input = document.getElementsByClassName('mapTypeField');

            var autocomplete = [];

            for (var i = 0; i < input.length; i++) {
                autocomplete.push(new google.maps.places.Autocomplete(input[i]));
            }

            var _loop = function _loop(j) {
                autocomplete[j].bindTo('bounds', _this.map);
                google.maps.event.addListener(autocomplete[j], 'place_changed', function () {
                    var place = autocomplete[j].getPlace();
                    if (typeof place == "undefined" || place == null || typeof place.geometry == "undefined" || typeof place.geometry.location.lat() == "undefined") return;

                    var location = new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());

                    instance.map.setCenter(location);
                    instance.map.setZoom(16);
                    instance.clearPolygonCoordinates();

                    $rootScope.$broadcast('location-found', place.formatted_address);
                });
            };

            for (var j = 0; j < autocomplete.length; j++) {
                _loop(j);
            }
        },

        getPolygonArea: function getPolygonArea() {
            return polygonArea;
        },

        getPolygonAreaInHectares: function getPolygonAreaInHectares() {
            return polygonArea * 0.0001;
        },

        getPolygonCoordinates: function getPolygonCoordinates() {
            return polygonCoordinates;
        },

        loadExistingPolygon: function loadExistingPolygon(coordinates) {
            // Construct the polygon.
            drawingManager.setDrawingMode(null);

            Polygon = new google.maps.Polygon({
                paths: coordinates,
                fillColor: '#F57713',
                strokeColor: '#F57713',
                clickable: true,
                editable: true
            });
            Polygon.setMap(this.map);
            this.map.setCenter(coordinates[0]);

            polygonArea = google.maps.geometry.spherical.computeArea(Polygon.getPath());

            google.maps.event.addListener(Polygon.getPath(), 'insert_at', function () {
                polygonCoordinates = Polygon.getPath().getArray();
                polygonArea = google.maps.geometry.spherical.computeArea(Polygon.getPath());
            });

            google.maps.event.addListener(Polygon.getPath(), 'set_at', function () {
                polygonCoordinates = Polygon.getPath().getArray();
                polygonArea = google.maps.geometry.spherical.computeArea(Polygon.getPath());
                $rootScope.$emit('polygon-overlay-changed');
            });

            google.maps.event.addListener(Polygon, 'rightclick', function (mev) {
                if (mev.vertex != null) {
                    Polygon.getPath().removeAt(mev.vertex);
                }
            });
        },

        clearPolygonCoordinates: function clearPolygonCoordinates() {

            for (var i = 0; i < mapOverlays.length; i++) {
                mapOverlays[i].overlay.setMap(null);
            }

            if (Polygon) {
                Polygon.setMap(null);
            }

            mapOverlays = [];
            polygonArea = 0;
            polygonCoordinates = null;
            drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
            $rootScope.$emit('autocomplete-address-selected');
        },

        getUserCurrentLocation: function getUserCurrentLocation() {
            var deferred = $q.defer();

            if (!navigator.geolocation) {
                deferred.reject("Your browser does not support location detection.");
                return;
            }

            var instance = this;

            var locationSuccessHandler = function locationSuccessHandler(position) {
                if (!instance.map) {
                    return;
                }
                var latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                instance.map.setCenter(latLng);
                instance.map.setZoom(16);

                deferred.resolve(position);
            };

            var locationErrorHandler = function locationErrorHandler(error) {
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        console.log("Geolocation: User denied the request.");
                        deferred.reject("Your browser seems to be denying us your location.\nCheck your location settings or try searching for the nearest town to your farm below.");
                        break;
                    case error.POSITION_UNAVAILABLE:
                        console.log("Geolocation: Location information is unavailable.");
                        deferred.reject("Oh shucks, we couldn’t find your location.\nTry searching for the nearest town to your farm below.");
                        break;
                    case error.TIMEOUT:
                        console.log("Geolocation: The request to get user location timed out.");
                        deferred.reject("Oh shucks, we couldn’t find your location.\nTry searching for the nearest town to your farm below.");
                        break;
                    case error.UNKNOWN_ERROR:
                        console.log("Geolocation: An unknown error occurred.");
                        deferred.reject("Oh shucks, we couldn’t find your location.\nTry searching for the nearest town to your farm below.");
                        break;
                }
            };

            var options = {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0
            };

            navigator.geolocation.getCurrentPosition(locationSuccessHandler, locationErrorHandler, options);

            return deferred.promise;
        }
    };
});
'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MissionMapUpload = function () {
    function MissionMapUpload(fileId, fileName, fileUrl, imageSource, imageType, imageDate, bucket, fullPath, uploadedAt) {
        _classCallCheck(this, MissionMapUpload);

        this.fileId = fileId;
        this.fileName = fileName;
        this.fileUrl = fileUrl;
        this.imageSource = imageSource;
        this.imageType = imageType;
        this.imageDate = imageDate;
        this.bucket = bucket || null;
        this.fullPath = fullPath || null;
        this.uploadedAt = uploadedAt || null;
    }

    _createClass(MissionMapUpload, [{
        key: 'uniqueRefKey',
        value: function uniqueRefKey() {
            // # The format must stay in sync with the automatic image_acquisition unique_key_ref_for_image_metadata function
            return moment(this.imageDate).format('YYYY-MM-DD') + '-' + this.imageSource + '-' + this.imageType;
        }
    }, {
        key: 'uploadedAtIso',
        value: function uploadedAtIso() {
            if (!this.uploadedAt) {
                return null;
            }

            return moment(this.uploadedAt).toISOString();
        }
    }, {
        key: 'uploadedAtMillis',
        value: function uploadedAtMillis() {
            if (!this.uploadedAt) {
                return null;
            }

            return this.uploadedAt.getTime();
        }
    }]);

    return MissionMapUpload;
}();
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

angular.module('farmpin').controller('MissionController', function ($scope, $state, $timeout, MissionSvc) {

    $scope.missionSvc = MissionSvc;
    var firebaseMission = null;
    $scope.savingForm = false;
    $scope.mapFiles = [];
    $scope.mapTypes = {};
    $scope.acquisitionTypes = {};
    $scope.fieldForm = {};
    $scope.farmUrl = "";
    $scope.uploadAllowed = false;
    $scope.fetchingImages = false;
    $scope.fetchingFields = false;
    $scope.addingNewField = false;

    $scope.sendMissionToPilot = function () {
        MissionSvc.updateMissionRequest().then(function () {
            return MissionSvc.sendMissionToPilot();
        });
    };

    $scope.sendFlightDataToProcessor = function () {
        MissionSvc.updateMissionRequest().then(function () {
            return MissionSvc.sendFlightDataToProcessor();
        });
    };

    $scope.sendFlightDataToExpert = function () {
        MissionSvc.updateMissionRequest().then(function () {
            return MissionSvc.sendFlightDataToExpert();
        });
    };

    $scope.updateMissionRequest = function () {
        $scope.savingForm = true;
        MissionSvc.updateMissionRequest().then(function () {
            return $scope.savingForm = false;
        });
    };

    $scope.changeClientSubscription = function () {
        MissionSvc.changeClientSubscription($state.params.mission_id);
    };

    $scope.addFarmField = function () {
        $scope.addingNewField = true;
        MissionSvc.addFarmField($scope.fieldForm).then(function () {
            getSelectedMission();
            $scope.addingNewField = false;
            $scope.fieldForm.field_name = '';
            $scope.fieldForm.coordinates = '';
        });
    };

    $scope.deleteFarmField = function (farmField) {
        MissionSvc.deleteFarmField(farmField).then(function () {
            getSelectedMission();
        });
    };

    $scope.fetchFarmFields = function () {
        $scope.fetchingFields = true;
        MissionSvc.fetchFarmFields().then(function () {
            getSelectedMission();
            $scope.fetchingFields = false;
        });
    };

    $scope.fetchMapImagesFromFirebase = function () {
        $scope.fetchingImages = true;
        var loadingPromises = [];

        loadingPromises.push(retrieveMapLayerTypesFromFirebase());
        loadingPromises.push(retrieveAcquisitionTypesFromFirebase());
        loadingPromises.push(retrieveExistingMapImagesFromFirebase());

        return Promise.all(loadingPromises).then(function () {
            $scope.$apply(function () {
                $scope.uploadAllowed = true;
            });
        });
    };

    var stringify = function stringify() {
        return JSON.stringify(this);
    };

    function getSelectedMission() {
        MissionSvc.getSelectedMission($state.params.mission_id).then(function () {
            // hack to display and input coordinate json
            // see https://stackoverflow.com/questions/17893708/angularjs-textarea-bind-to-json-object-shows-object-object
            $scope.missionSvc.currentMission.coordinates.toString = stringify;
            $scope.farmUrl = '/farm/' + $scope.missionSvc.currentMission.id;
            $scope.upload_field_files_url = '/admin/api/mission/' + $scope.missionSvc.currentMission.id + '/upload_fields';
            $scope.$watch('missionSvc.currentMission.coordinates', function (v) {
                // console.log(v);
                if (typeof v === 'string') {
                    $scope.missionSvc.currentMission.coordinates = JSON.parse(v);
                    $scope.missionSvc.currentMission.coordinates.toString = stringify;
                }
            });
        });
    }

    $scope.init = function () {
        getSelectedMission();

        $(document).ready(function () {

            $('.modal').modal();

            //prepare file upload functionality
            var mapFileUpload = document.getElementById("mapFileUpload");
            mapFileUpload.addEventListener('change', function (e) {
                var files = e.target.files;
                if (!files || files.length === 0) return;

                var uploadPromises = [];

                var _loop = function _loop(i) {

                    var file = files[i];

                    if (!file.name) return {
                            v: void 0
                        };
                    jQuery('#uploading-map-files').removeClass('hidden');

                    var uploadLocation = '/mission/' + $scope.missionSvc.currentMission.id + '/images/';
                    var uploadPromise = uploadFileToCloudStorage(uploadLocation, file, null).then(function (storageInfo) {

                        var fileDate = autoDetermineDateFromFilename(file.name);
                        var imageType = autoDetermineImageTypeFromFilename(file.name);

                        var defaultAcquisitionType = getDefaultAcquisitionType();
                        jQuery('#uploading-map-files').addClass('hidden');
                        $scope.mapFiles.push(new MissionMapUpload(generateUniqueMapImageFileId(), storageInfo.name, storageInfo.downloadUrl, defaultAcquisitionType, imageType, fileDate, storageInfo.bucket, storageInfo.fullPath, storageInfo.uploadedAt));

                        $scope.$apply();
                        setDatePickerControls();
                    }, function (errMessage) {

                        jQuery('#uploading-map-files').addClass('hidden');
                        alert(errMessage);
                    }).then(function () {
                        // we don't sort the mapFiles again because we'd also need to sort the datePicker controls...
                        for (var _i = 0; _i < $scope.mapFiles.length; _i++) {
                            var $datePickerControl = $('#mapFileDate' + _i).pickadate();
                            var datePicker = $datePickerControl.pickadate('picker');
                            datePicker.set('select', $scope.mapFiles[_i].imageDate);
                        }
                    }, function (errMessage) {

                        jQuery('#uploading-map-files').addClass('hidden');
                        alert(errMessage);
                    });

                    uploadPromises.push(uploadPromise);
                };

                for (var i = 0; i < files.length; i++) {
                    var _ret = _loop(i);

                    if ((typeof _ret === 'undefined' ? 'undefined' : _typeof(_ret)) === "object") return _ret.v;
                } // end file for loop

                Promise.all(uploadPromises).then(function (results) {
                    $scope.saveMissionMapData();
                });
            });
        });
    };

    $scope.selectImageType = function (type, index) {
        $scope.mapFiles[index].imageType = type;
    };

    $scope.selectImageSource = function (imageSource, index) {
        $scope.mapFiles[index].imageSource = imageSource;
    };

    $scope.deleteMapImage = function (index) {
        $scope.mapFiles[index].fileId = "";
    };

    $scope.saveMissionMapData = function () {

        // remove the custom helper function from the copied coordinates
        var cloned_coordinates = Object.assign({}, $scope.missionSvc.currentMission.coordinates);
        delete cloned_coordinates.toString;

        var missionMapPayload = {
            name: $scope.missionSvc.currentMission.name,
            email: $scope.missionSvc.currentMission.email,
            cell: $scope.missionSvc.currentMission.cell,
            coordinates: cloned_coordinates,
            pins: firebaseMission.pins || []
        };

        firebase.database().ref('mission_map_images/' + $scope.missionSvc.currentMission.id).set(missionMapPayload).then(function () {

            var promises = [];
            for (var i = 0; i < $scope.mapFiles.length; i++) {

                var _file = $scope.mapFiles[i];

                if (_file.fileId.length > 0) {
                    //exclude deleted map image files

                    var imageData = {
                        name: _file.fileName,
                        url: _file.fileUrl,
                        acquisition_type: _file.imageSource,
                        type: _file.imageType,
                        date: setMapImageDate(new Date(jQuery('#mapFileDate' + i).val())),
                        bucket: _file.bucket,
                        path: _file.fullPath,
                        uploadedAt: _file.uploadedAtMillis(),
                        uploadedAtIso: _file.uploadedAtIso()
                    };

                    promises.push(firebase.database().ref('mission_map_images/' + $scope.missionSvc.currentMission.id + '/images').child(_file.uniqueRefKey()).update(imageData));
                }
            }

            Promise.all(promises).then(function (snapshots) {

                $('#modalSuccess').modal('open');
                retrieveExistingMapImagesFromFirebase();
            });
        });
    };

    function retrieveExistingMapImagesFromFirebase() {

        return firebase.database().ref('mission_map_images/' + $scope.missionSvc.currentMission.id).once('value').then(function (snapshot) {

            if (!snapshot || !snapshot.val()) return;
            firebaseMission = snapshot.val();

            $scope.mapFiles = [];
            for (var item in firebaseMission.images) {
                var image = firebaseMission.images[item];
                $scope.mapFiles.push(new MissionMapUpload(item, image.name, image.url, image.acquisition_type, image.type, new Date(image.date), image.bucket, image.path, image.uploadedAtIso ? new Date(image.uploadedAtIso) : null));
                $scope.mapFiles.sort(function (a, b) {
                    return a.imageDate - b.imageDate;
                });
                $scope.$apply();
                setDatePickerControls();
            }

            for (var i = 0; i < $scope.mapFiles.length; i++) {
                var $datePickerControl = $('#mapFileDate' + i).pickadate();
                var datePicker = $datePickerControl.pickadate('picker');
                datePicker.set('select', $scope.mapFiles[i].imageDate);
            }
        });
    }

    function retrieveAcquisitionTypesFromFirebase() {
        return firebase.database().ref('acquisition_types').once('value').then(function (snapshot) {
            $scope.acquisitionTypes = snapshot.val();
            console.log($scope.acquisitionTypes);
        });
    }

    function getDefaultAcquisitionType() {
        var keys = Object.keys($scope.acquisitionTypes);
        for (var id = 0; id < keys.length; id++) {
            var key = keys[id];
            var acType = $scope.acquisitionTypes[key];
            if (acType.hasOwnProperty('default') && acType.default) {
                return key;
            }
        }

        throw new Error('No default acquisition type has been defined. Refer to Firebase definitions');
    }

    function retrieveMapLayerTypesFromFirebase() {
        return firebase.database().ref('map_layer_types').orderByChild('priority').once('value').then(function (snapshot) {
            var mapTypes = {};
            snapshot.forEach(function (child) {
                console.log(child.val());
                mapTypes[child.key] = child.val();
            });

            $scope.mapTypes = mapTypes;
        });
    }

    function setMapImageDate(date) {
        return moment(date).format('YYYY-MM-DD');
    }

    function setDatePickerControls() {

        setTimeout(function () {

            $('.datepicker').pickadate({
                selectMonths: true, // Creates a dropdown to control month
                selectYears: 15 // Creates a dropdown of 15 years to control year
            });
        }, 500);
    }

    function autoDetermineDateFromFilename(fileName) {
        var dateFormat1Re = new RegExp('(\\d{2} [a-zA-Z]{3} \\d{4})', 'i');
        var dateFormat2Re = new RegExp('(\\d{4}-\\d{2}-\\d{2})', 'i');

        var match1 = fileName.match(dateFormat1Re);
        if (match1) {
            try {
                return moment(match1[1], 'DD MMM YYYY').toDate();
            } catch (err) {
                console.error(err);
                return new Date();
            }
        }

        var match2 = fileName.match(dateFormat2Re);
        if (match2) {
            try {
                return moment(match2[1], 'YYYY-MM-DD').toDate();
            } catch (err) {
                console.error(err);
                return new Date();
            }
        }

        //default value
        return new Date();
    }

    function autoDetermineImageTypeFromFilename(fileName) {

        //based on file name configurations from Sentinel, extract first portion of string before hyphen - this represents the map-type code

        var hyphenIndex = fileName.indexOf("-");
        if (hyphenIndex === -1) {
            return "MISC";
        }
        var mapType = fileName.substr(0, hyphenIndex).trim().toUpperCase();
        if (mapType.length === 0) {
            return "MISC";
        }

        return mapType;
    }

    function uploadFileToCloudStorage(location, file, listener) {

        /**RECEIVES A FILE FROM FILE INPUT AND SAVES TO FIREBASE STORAGE. RETURNS PROMISE CONTAINING FILE IMAGE URL**/
        console.log(file);

        return new Promise(function (resolve, reject) {
            var uploadRef = firebase.storage().ref().child(location + file.name);
            var uploadTask = uploadRef.put(file);

            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            function (snapshot) {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
                console.log('Upload is ' + progress + '% done');
                if (!!listener) listener(progress);
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED:
                        // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING:
                        // or 'running'
                        console.log('Upload is running');
                        break;
                }
            }, function (error) {
                switch (error.code) {
                    case 'storage/unauthorized':
                        console.log('User doesn\'t have permission to access the object');
                        reject('User doesn\'t have permission to access the object');
                        break;

                    case 'storage/canceled':
                        console.log('User canceled the upload');
                        reject('User canceled the upload');
                        break;
                    case 'storage/unknown':
                        console.log('Unknown error occurred, inspect error.serverResponse');
                        reject('Unknown error occurred, inspect error.serverResponse');
                        break;
                }
            }, function () {
                // Upload completed successfully, now we can get the download URL
                var downloadURL = uploadTask.snapshot.downloadURL;
                resolve({
                    gsPath: uploadRef.toString(),
                    bucket: uploadRef.bucket,
                    fullPath: uploadRef.fullPath,
                    name: uploadRef.name,
                    fileName: file.name,
                    downloadUrl: downloadURL,
                    uploadedAt: new Date()
                });
            });
        });
    }

    $scope.init();

    function generateUniqueMapImageFileId() {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < 5; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }return text;
    }
});
'use strict';

angular.module('farmpin').factory('MissionSvc', function ($http) {

    return {

        currentMission: null,

        initiateMission: function initiateMission(formData) {
            var _this = this;

            var req = {
                method: 'POST',
                url: '/api/mission/',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 8000,
                data: {
                    'name': formData.name,
                    'email': formData.email,
                    'cell': formData.cell,
                    'language': formData.language,
                    'crops': formData.crops
                }
            };

            return $http(req).then(function (response) {
                _this.currentMission = response.data;
                return response;
            });
        },

        updateMissionRequest: function updateMissionRequest(coordinates, area) {
            if (angular.isDefined(coordinates)) {
                this.currentMission.coordinates = coordinates;
                this.currentMission.coordinates.toString = function () {
                    return JSON.stringify(this);
                };
            }

            if (angular.isDefined(area)) {
                this.currentMission.area = area;
            }

            var req = {
                method: 'PUT',
                url: '/api/mission/' + this.currentMission.id,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 8000,
                data: {
                    'mission': this.currentMission
                }
            };

            return $http(req);
        },

        sendMissionDetailsToAdmin: function sendMissionDetailsToAdmin(missionId) {
            return $http.get('/api/mission/' + missionId + '/email');
        },

        getSelectedMission: function getSelectedMission(missionId) {
            var _this2 = this;

            return $http.get('/api/mission/' + missionId).then(function (response) {
                _this2.currentMission = response.data.mission;
                return response;
            });
        },

        sendMissionToPilot: function sendMissionToPilot() {
            return $http.get('/api/mission/' + this.currentMission.id + '/email/pilot');
        },

        sendFlightDataToProcessor: function sendFlightDataToProcessor() {
            return $http.get('/api/mission/' + this.currentMission.id + '/email/processor');
        },

        sendFlightDataToExpert: function sendFlightDataToExpert() {
            return $http.get('/api/mission/' + this.currentMission.id + '/email/expert');
        },

        sendLiveShareLinkToAdmin: function sendLiveShareLinkToAdmin(liveShareLink) {
            return $http.get('/api/mission/' + this.currentMission.id + '/email/live_share_link?link=' + encodeURIComponent(liveShareLink));
        },
        changeClientSubscription: function changeClientSubscription(mission_id) {
            return $http.get('/admin/api/mission/' + mission_id + '/change_subscription');
        },

        addFarmField: function addFarmField(fieldDetails) {
            var req = {
                method: 'POST',
                url: '/admin/api/field/create/' + this.currentMission.id,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 8000,
                data: {
                    'field_name': fieldDetails.field_name,
                    'coordinates': fieldDetails.coordinates
                }
            };

            return $http(req);
        },

        deleteFarmField: function deleteFarmField(farmField) {
            return $http.get('/admin/api/field/delete/' + this.currentMission.id + '/' + farmField.id);
        },

        fetchFarmFields: function fetchFarmFields() {
            return $http.get('/api/mission/' + this.currentMission.id + '/fetch_farm_fields');
        }
    };
});
'use strict';

angular.module('farmpin').controller('PayController', function ($scope, $state, $timeout, MissionSvc, PayService) {
    $scope.formData = {};
    $scope.redirect_url = {};
    $scope.payment_params = {};
    $scope.confirm_payment = false;
    $scope.formSubmission = false;
    $scope.formData.mission_id = $state.params.mission_id;
    $scope.allowedCardTypes = ['Visa', 'MasterCard'];

    $scope.paymentMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    $scope.selectedMonth = $scope.paymentMonths[0];

    $scope.submitPaymentForm = function (cardType) {
        if (cardType != null) {
            $scope.formSubmission = true;
            $scope.formData.card_type = cardType;
            PayService.submitPaymentForm($scope.formData).then(function (res) {
                $scope.formSubmission = false;
                $scope.payment_params = res.data.context.params;
                // 3d Secure requires us to post some params to a url, doing this through $http results in a cors error so have to do it this way, unless?
                document.getElementById("paymentSubmit").action = res.data.context.redirect_url;
                $scope.confirm_payment = true;
            });
        }
    };

    $scope.backToPayment = function () {
        $scope.confirm_payment = false;
    };

    // 3d Secure requires us to post some params to a url, doing this through $http results in a cors error so have to do it this way, unless?
    $scope.confirmPayment = function () {
        document.getElementById("paymentSubmit").submit();
    };

    $scope.init = function () {
        MissionSvc.getSelectedMission($scope.formData.mission_id).then(function (res) {
            $scope.formData.card_holder = res.data.mission.name;
            $scope.formData.email = res.data.mission.email;
        });

        $scope.formData.ref = '';
        $scope.formData.card_no = '';
        $scope.formData.card_holder = '';
        $scope.formData.expiry_month = '';
        $scope.formData.expiry_year = '';
        $scope.formData.cvv = '';
    };
    $scope.init();
});
'use strict';

angular.module('farmpin').controller('PayResponseController', function ($scope, $state, PayService) {

    $scope.test = $state.params.id;

    $scope.init = function () {
        PayService.fetchResponse($state.params.id).then(function (res) {
            $scope.pay_response = res.data.response.result;
        });
    };

    $scope.init();
});
'use strict';

angular.module('farmpin.mygate', []).factory('MyGateService', ['$http', function ($http) {
    var service = {
        check3dSecure: function check3dSecure(cardInfo, requestId) {
            var instance = this;

            var values = {
                'card_no': cardInfo.card_no,
                'card_type': cardInfo.ccType,
                'card_holder': cardInfo.card_holder,
                'expiry_month': cardInfo.expiry_month,
                'expiry_year': cardInfo.expiry_year,
                'cvv': cardInfo.cvv,

                'sell_id': requestId
            };

            var req = {
                method: 'POST',
                url: '/api/mygate/3dsecurelookup',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 80000,
                data: { values: values }
            };

            return $http(req).then(function successHandler(response) {
                console.log(response);
                if (response.data.require3d) {
                    instance._post_to_3d_secure(response.data);
                } else {
                    return instance.process_and_settle(response.data, cardInfo, requestId); // pass in transaction index
                }
            }, function errorHandler(response) {
                console.log(response);
            });
        },

        _add_form_fields: function _add_form_fields(form, data) {
            if (data != null) {
                $.each(data, function (name, value) {
                    if (value != null) {
                        var input = $("<input></input>").attr("type", "hidden").attr("name", name).val(value);
                        form.append(input);
                    }
                });
            }
        },

        _post_to_3d_secure: function _post_to_3d_secure(lookup_response) {
            var fields = {
                PaReq: lookup_response.pareqmsg,
                MD: lookup_response.transactionindex,
                TermUrl: lookup_response.termurl
            };

            // build form
            var form = $('<form></form>');
            form.attr("action", lookup_response.acsurl);
            form.attr("method", "POST");
            form.attr("style", "display:none;");
            this._add_form_fields(form, fields);
            $("body").append(form);

            // submit form
            form.submit();
            form.remove();
        },

        process_and_settle: function process_and_settle(secure3d_data, cardInfo, requestId) {

            var values = {
                'card_no': cardInfo.card_no,
                'card_type': cardInfo.ccType,
                'card_holder': cardInfo.card_holder,
                'expiry_month': cardInfo.expiry_month,
                'expiry_year': cardInfo.expiry_year,
                'cvv': cardInfo.cvv,
                'transaction_index': secure3d_data.transactionindex,

                'sell_id': requestId
            };

            var req = {
                method: 'POST',
                url: '/api/mygate/processandsettle',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 80000,
                data: { values: values }
            };

            return $http(req).then(function successHandler(response) {
                // if (response.data == 0 || response.data == 1) {
                //     $scope.process_payment = false;
                //     $scope.payment = true;
                //     $state.transitionTo('sell-safe/form.pick-up',{request_id: $scope.request_id});
                // } else {
                //     $scope.process_payment = false;
                //     $scope.mygate_failed = response.data;
                //     $('#modal1').openModal();
                // }

            }, function errorHandler(response) {
                console.log(response);
            });
        }
    };

    return service;
}]);
'use strict';

angular.module('farmpin').factory('PayService', function ($http) {

    var service = {

        submitPaymentForm: function submitPaymentForm(formData) {
            var req = {
                method: 'POST',
                url: '/api/peach/',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 8000,
                data: {
                    'payment': formData
                }
            };

            return $http(req);
        },

        fetchResponse: function fetchResponse(token_id) {
            return $http.get('/api/peach/' + token_id);
        }

    };

    return service;
});