class MissionMapUpload {
    constructor(fileId, fileName, fileUrl, imageSource, imageType, imageDate, bucket, fullPath, uploadedAt) {
        this.fileId = fileId;
        this.fileName = fileName;
        this.fileUrl = fileUrl;
        this.imageSource = imageSource;
        this.imageType = imageType;
        this.imageDate = imageDate;
        this.bucket = bucket || null;
        this.fullPath = fullPath || null;
        this.uploadedAt = uploadedAt || null;
    }

    uniqueRefKey() {
        // # The format must stay in sync with the automatic image_acquisition unique_key_ref_for_image_metadata function
        return `${moment(this.imageDate).format('YYYY-MM-DD')}-${this.imageSource}-${this.imageType}`;
    }

    uploadedAtIso() {
        if(!this.uploadedAt){
            return null;
        }

        return moment(this.uploadedAt).toISOString();
    }

    uploadedAtMillis() {
        if(!this.uploadedAt){
            return null;
        }

        return this.uploadedAt.getTime();
    }
}
