class MissionDate {
    constructor(imageDate, imageSource) {
        this.imageDate = imageDate;
        this.imageSource = imageSource;
    }

    equals(other){
        if (other === null || angular.isUndefined(other)) {
            return false;
        }

        return (this.imageDate.getTime() === other.imageDate.getTime() && this.imageSource === other.imageSource);
    }
}
